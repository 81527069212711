import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const ChatPlugin = () => {
  const [configID, setConfigId] = useState(0)
  const [giftConfigID, setGiftConfigID] = useState(0)
  const [chatConfig, setChatConfig] = useState<any>({
    isCreditDeduct: true,
    creditPerMessage: 0,
    creditPerMedia: 0,
    transferMediaCreditPercentage: 0,
    isTransferCredit: true,
    deductCreditGender: null,
    transferCreditGender: null,
    isShowCredit: false,
  })

  const [giftConfig, setGiftConfig] = useState<any>({
    isTransferCredit: true,
    deductCreditGender: null,
    transferCreditPercentage: 100,
  })

  useEffect(() => {
    getConfiguration()
    getGiftConfiguration()
  }, [])

  const handleChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked
    // isCreditDeduct
    if (name === 'isGlobalChatBotEnabled') {
      setChatConfig({...chatConfig, [name]: !value})
      updateConfiguration({...chatConfig, [name]: value})
    } else if (
      name !== 'deductCreditGender' &&
      name !== 'creditPerMessage' &&
      name !== 'creditPerMedia' &&
      name !== 'transferMediaCreditPercentage'
    ) {
      setChatConfig({...chatConfig, [name]: value})
      updateConfiguration({...chatConfig, [name]: value})
    } else {
      setChatConfig({...chatConfig, [name]: event.target.value})
      if (name === 'deductCreditGender') {
        let value1 = event.target.value === 0 ? null : event.target.value
        updateConfiguration({...chatConfig, [name]: value1})
      }
    }
  }

  const handleGiftChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    //"isTransferCredit": true, "deductCreditGender": null, "transferCreditPercentage": 100

    if (name === 'isTransferCredit') {
      setGiftConfig({...giftConfig, [name]: value})
      updateGiftConfiguration({...giftConfig, [name]: value})
    } else {
      setGiftConfig({...giftConfig, [name]: event.target.value})
      if (name === 'deductCreditGender') {
        let value1 = event.target.value === 0 ? null : event.target.value
        updateGiftConfiguration({...giftConfig, [name]: value1})
      }
    }
  }

  const onBlurUpdate = () => {
    updateConfiguration(chatConfig)
  }

  const onGiftBlurUpdate = () => {
    updateGiftConfiguration(giftConfig)
  }

  const getConfiguration = async () => {
    let result = await getConfigurationByName('chat')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigId(result.data.id)
      setChatConfig({
        ...parsedData,
        isCreditDeduct: parsedData?.isCreditDeduct,
        creditPerMessage: parsedData?.creditPerMessage,
        creditPerMedia: parsedData?.creditPerMedia,
        transferMediaCreditPercentage: parsedData?.transferMediaCreditPercentage,
        isTransferCredit: parsedData?.isTransferCredit,
        deductCreditGender:
          parsedData?.deductCreditGender === null ? 0 : parsedData?.deductCreditGender,
        transferCreditGender: parsedData?.transferCreditGender,
        isGlobalChatBotEnabled: parsedData?.isGlobalChatBotEnabled,
      })
    }
  }

  const getGiftConfiguration = async () => {
    let result = await getConfigurationByName('send-gift')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setGiftConfigID(result.data.id)
      setGiftConfig({
        isTransferCredit: parsedData.isTransferCredit,
        deductCreditGender: parsedData.deductCreditGender,
        transferCreditPercentage: parsedData.transferCreditPercentage,
      })
    }
  }

  const updateConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  const updateGiftConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(giftConfigID, config, null)
    if (result.success) {
      getGiftConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>Chat settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Credit Deduction</strong>
            </p>
            <p className='text-muted'>Enable / Disable Credit Deduction</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                Enable Credit Deduction
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isCreditDeduct'
                checked={chatConfig.isCreditDeduct}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Charge credits per message</strong>
            </p>
            <p className='text-muted'>Charge credits every new message is sent</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Charge credits price</label>
              <br />

              <input
                type='number'
                className='form-control'
                name='creditPerMessage'
                value={chatConfig.creditPerMessage}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Charge credits per Media</strong>
            </p>
            <p className='text-muted'>Charge credits every new Media is sent</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditPerMedia'>Charge credits price</label>
              <br />

              <input
                type='number'
                className='form-control'
                name='creditPerMedia'
                value={chatConfig.creditPerMedia}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer Media Credit Percentage</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='transferMediaCreditPercentage'>
                Transfer Media Credit Percentage
              </label>
              <br />

              <input
                type='number'
                className='form-control'
                name='transferMediaCreditPercentage'
                value={chatConfig.transferMediaCreditPercentage}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Charge credits to specific gender</strong>
            </p>
            <p className='text-muted'>Charge credits per message only to the selected gender</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessageGender'>Charge credits gender</label>
              <br />
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='gender'
                data-hide-search='true'
                defaultValue={chatConfig.deductCreditGender}
                name='deductCreditGender'
                value={chatConfig.deductCreditGender}
                onChange={(event) => handleChange(event)}
              >
                <option value={0}>All Gender</option>
                <option value={1}>Male</option>
                <option value={2}>Female</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer credits of message to receiver</strong>
            </p>
            <p className='text-muted'>
              If <code>Charge credits htmlFor message</code> is enabled, enable this htmlFor
              transfer the cost of the credit htmlFor send the message to the receiver of the
              message
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                Transfer Message Credits to Receiver
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isTransferCredit'
                checked={chatConfig.isTransferCredit}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          {/*Gift Config */}

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer credits of gifts to receiver</strong>
            </p>
            <p className='text-muted'>
              Enable this htmlFor transfer the credit cost of the gift to the receiver of the
              message
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                Transfer Gifts Credits to Receiver
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isTransferCredit'
                checked={giftConfig.isTransferCredit}
                onChange={(event) => handleGiftChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer Gift Credit Percentage</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Transfer Credit Percentage</label>
              <br />

              <input
                type='number'
                className='form-control'
                name='transferCreditPercentage'
                value={giftConfig.transferCreditPercentage}
                onChange={(event) => handleGiftChange(event)}
                onBlur={onGiftBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Charge credits to specific gender for gift</strong>
            </p>
            <p className='text-muted'>
              Charge credits per message only to the selected gender for gift
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessageGender'>Charge credits gender</label>
              <br />
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='gender'
                data-hide-search='true'
                defaultValue={giftConfig.deductCreditGender}
                name='deductCreditGender'
                value={giftConfig.deductCreditGender}
                onChange={(event) => handleGiftChange(event)}
              >
                <option value={0}>All Gender</option>
                <option value={1}>Male</option>
                <option value={2}>Female</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatPlugin
