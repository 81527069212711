import axios from 'axios'
import CryptoJS from 'crypto-js'

const apiBaseURL = process.env.REACT_APP_SERVER_URL + '/api/v1' // Replace with your API base URL

const cryptoSecretKey =
  process.env.REACT_APP_CRYPTO_SECRET_KEY || 'c8FlAzPXeQ7IjeT2jrNs4XHEI8Jxz9zJ'
const cryptoIv = process.env.REACT_APP_CRYPTO_IV || 'I6hcQzxIAlvmPZUG'

const isEncryption = process.env.REACT_APP_IS_ENCRYPTION || 'true'

const request = async (uri, method, data = {}, passHeader = false) => {
  const url = `${apiBaseURL}${uri}`

  const bodyData = prepareBodyData(data)
  const headers = prepareHeaders(passHeader)

  try {
    let response

    switch (method) {
      case 'POST':
        response = await axios.post(url, bodyData, {headers})
        break
      case 'DELETE':
        response = await axios.delete(url, {data: bodyData, headers})
        break
      case 'PUT':
        response = await axios.put(url, bodyData, {headers})
        break
      case 'GET':
        response = await axios.get(url, {headers})
        break
      default: // GET method
        response = await axios.get(url, {headers})
        break
    }

    let result = handleResponse(response)

    return result
  } catch (error) {
    if (error.response) {
      return handleResponse(error.response)
    } else if (error.request) {
      return {success: false, message: 'No Internet Connection', statusCode: 503}
    } else {
      console.error('Error', error.message)
      return {success: false, message: 'Something went wrong', statusCode: 499}
    }
  }
}

const prepareBodyData = (params) => {
  const jsonString = JSON.stringify(params)
  if (isEncryption === 'true') {
    const encryptedData = encryptData(jsonString)
    return {dataStr: encryptedData}
  }
  return jsonString
}

const prepareHeaders = (passHeader) => {
  const headers = {
    isencryption: isEncryption,
    'Content-Type': 'application/json',
  }

  if (passHeader) {
    let token = initToken()
    headers['Accept'] = 'application/json'
    headers['x-access-token'] = token
  }

  return headers
}

function encryptData(data) {
  return CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(cryptoSecretKey), {
    iv: CryptoJS.enc.Utf8.parse(cryptoIv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString()
}

function decryptData(dataStr) {
  const bytes = CryptoJS.AES.decrypt(dataStr, CryptoJS.enc.Utf8.parse(cryptoSecretKey), {
    iv: CryptoJS.enc.Utf8.parse(cryptoIv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}

const handleResponse = (response) => {
  if (response?.status === 200) {
    return {
      success: true,
      message: 'Success',
      statusCode: 200,
      data:
        isEncryption === 'true'
          ? prepareResponseData(response.data.data)
          : JSON.stringify(response.data.data),
    }
  } else {
    const message = response.data.message || 'Something went wrong'
    switch (response.status) {
      case 400:
        return {success: false, message, status: 400}
      case 401:
        return {success: false, message, status: 401}
      case 404:
      case 403:
      case 409:
      case 500:
      case 503:
        return {success: false, message, status: response.status}
      default:
        return {
          success: false,
          message: message,
          status: 499,
          response: response.data,
        }
    }
  }
}

const prepareResponseData = (responseModel) => {
  const decryptedData = decryptData(responseModel)
  return decryptedData
}

const initToken = () => {
  let accessToken = localStorage.getItem('accessToken')
  // const accessTokenType = localStorage.getItem('accessTokenType')

  //   return accessTokenType + accessToken
  return accessToken
}

export {request}
