const PluginData = [
  {
    pluginId: 1,
    icon: '/media/plugins/jstree/profileQuestions.jpg',
    name: 'Profile questions and Interest',
    type: 'Software',
    description: 'Customize your users profile with your own custom questions and interest system',
    status: 'Active',
    path: 'question_answer_plugin',
  },
  {
    pluginId: 2,
    icon: '/media/plugins/jstree/gifts.jpg',
    name: 'Gifts',
    type: 'Software',
    description: 'Allow users to send gift eachother',
    status: 'Active',
    path: 'gifts_plugin',
  },
  {
    pluginId: 3,
    icon: '/media/plugins/jstree/chat.jpg',
    name: 'Chat',
    type: 'Pages',
    description: 'Configure the messaging system',
    status: 'Active',
    path: 'chat_plugin',
  },
  {
    pluginId: 4,
    icon: '/media/plugins/jstree/rewards.jpg',
    name: 'Rewards',
    type: 'Software',
    description: 'Give free credits to users after complete some tasks in the site',
    status: 'Active',
    path: 'reward_plugin',
  },
  {
    pluginId: 5,
    icon: '/media/plugins/jstree/credits.jpg',
    name: 'Site pricing',
    type: 'Software',
    description: 'Update the credits and premium pricing details',
    status: 'Active',
    path: 'site_pricing_plugin',
  },
  {
    pluginId: 7,
    icon: '/media/plugins/jstree/populars.jpg',
    name: 'Global Online',
    type: 'Pages',
    description: 'Shows the global online users in the platform',
    status: 'Active',
    path: 'global_online_plugin',
  },
  {
    pluginId: 8,
    icon: '/media/plugins/jstree/spotlight.jpg',
    name: 'Spotlight',
    type: 'Software',
    description: 'Allow users to get into the spotlight and get seen more by others',
    status: 'Active',
    path: 'spotlight_plugin',
  },

  {
    pluginId: 10,
    icon: '/media/plugins/jstree/discover.jpg',
    name: 'Discover game',
    type: 'Pages',
    description: 'Like or pass to people order by geolocation and user search criteria',
    status: 'Active',
    path: 'discover_game_plugin',
  },
  {
    pluginId: 11,
    icon: '/media/plugins/jstree/settings.jpg',
    name: 'General Settings',
    type: 'Software',
    description: 'Software general settings',
    status: 'Active',
    path: 'general_plugin',
  },
  {
    pluginId: 12,
    icon: '/media/plugins/jstree/verification.jpg',
    name: 'Verification system',
    type: 'Software',
    description: 'Allow users to verify their profile by copying a gesture',
    status: 'Active',
    path: 'verification_system_plugin',
  },
  {
    pluginId: 13,
    icon: '/media/plugins/jstree/terms.jpg',
    name: 'Legal information',
    type: 'Pages',
    description: 'Edit your site terms of use, privacy policy and cookies',
    status: 'Active',
    path: 'legal_information_plugin',
  },
  {
    pluginId: 14,
    icon: '/media/plugins/jstree/manualGateways.png',
    name: 'Manual Payment Gateways',
    type: 'Payment',
    description: 'Edit Manual Payment Gateways (Not integrated)',
    status: 'Active',
    path: 'manual_payment_gateways_plugin',
  },
  {
    pluginId: 15,
    icon: '/media/plugins/jstree/phonepe.png',
    name: 'Phone Pay',
    type: 'Payment',
    description: 'Edit Phone Pay Gateways',
    status: 'Active',
    path: 'phone_pay_payment_plugin',
  },
  {
    pluginId: 16,
    icon: '/media/plugins/jstree/stripe.jpg',
    name: 'Stripe',
    type: 'Payment',
    description: 'Edit Stripe Gateways (Not integrated)',
    status: 'Active',
    path: 'stripe_payment_plugin',
  },
  {
    pluginId: 18,
    icon: '/media/plugins/jstree/razorpay.png',
    name: 'Razor Pay',
    type: 'Payment',
    description: 'Edit Razor Pay Gateways (Not integrated)',
    status: 'Active',
    path: 'razor_pay_payment_plugin',
  },
  {
    pluginId: 19,
    icon: '/media/plugins/jstree/folder.webp',
    name: 'Folder Structure',
    type: 'Software',
    description: 'Edit Folder Size in Folder Structure',
    status: 'Active',
    path: 'folder_structure_plugin',
  },
  {
    pluginId: 20,
    icon: '/media/plugins/jstree/fakeusers.jpg',
    name: 'Anonymous User Interaction',
    type: 'Software',
    description:
      'Gives life to your ONLINE fake users, visiting and liking real users after their visit or like them',
    status: 'Active',
    path: 'user_interaction_plugin',
  },
  {
    pluginId: 21,
    icon: '/media/plugins/jstree/withdrawal.jpg',
    name: 'Withdrawal',
    type: 'Premium Plugin',
    description: 'Allow users to convert credits into real money',
    status: 'Active',
    path: 'withdrawal_plugin',
  },
  {
    pluginId: 22,
    icon: '/media/plugins/jstree/smtp.png',
    name: 'SMTP config',
    type: 'Tools',
    description: 'you can change dynamically configuration of smtp ',
    status: 'Active',
    path: 'smtp_plugin',
  },
  {
    pluginId: 23,
    icon: '/media/plugins/jstree/ads.jpg',
    name: 'Ads config',
    type: 'Tools',
    description: 'you can change dynamically configuration of ads',
    status: 'Active',
    path: 'ads_plugin',
  },
  {
    pluginId: 24,
    icon: '/media/plugins/jstree/story.jpg',
    name: 'Story config',
    type: 'Tools',
    description: 'you can change dynamically configuration of Story',
    status: 'Active',
    path: 'story_plugin',
  },
  {
    pluginId: 25,
    icon: '/media/plugins/jstree/ads-planner.webp',
    name: 'Ads Planner',
    type: 'Tools',
    description: 'you can change dynamically configuration of Ads Planner',
    status: 'Active',
    path: 'ads_plan_plugin',
  },
  {
    pluginId: 26,
    icon: '/media/plugins/jstree/notification.png',
    name: 'Notification',
    type: 'Tools',
    description: 'Customize your Notifications',
    status: 'Active',
    path: 'notification_plugin',
  },
  {
    pluginId: 27,
    icon: '/media/plugins/jstree/megachatbot.png',
    name: 'Mega ChatBot',
    type: 'Tools',
    description: 'Customize Mega Chat Bot',
    status: 'Active',
    path: 'mega_chat_bot_plugin',
  },
  // {
  //   pluginId: 28,
  //   icon: '/media/plugins/jstree/smtp.jpg',
  //   name: 'Moderator Level System',
  //   type: 'Tools',
  //   description: 'Customize Moderator Level System',
  //   status: 'Active',
  //   path: 'moderator_level_system',
  // },
  {
    pluginId: 29,
    icon: '/media/plugins/jstree/live-stream.png',
    name: 'Live Streaming',
    type: 'Tools',
    description: 'Customize Live Streaming Configuration',
    status: 'Active',
    path: 'live_streaming_plugin',
  },
  {
    pluginId: 9,
    icon: '/media/plugins/jstree/videocall.jpg',
    name: 'Videochat',
    type: 'Software',
    description: 'Peer to peer videocalling ',
    status: 'Active',
    path: 'videocall_plugin',
  },
  {
    pluginId: 30,
    icon: '/media/plugins/jstree/videocall.jpg',
    name: 'Currency Rate',
    type: 'Tools',
    description: 'Currency Rate in USD',
    status: 'Active',
    path: 'currency_rate_plugin',
  },
]

export default PluginData
