import colors from 'colors'
import fs from 'fs'

// Helper function to format the current timestamp
const getTimestamp = (): string => {
  return new Date().toISOString()
}

// Log levels for better type safety
type LogLevel = 'error' | 'info' | 'debug' | 'success' | 'warn'

// Helper function to log messages to a file
const logToFile = (message: string, level: LogLevel): void => {
//   const logMessage = `[${getTimestamp()}] [${level.toUpperCase()}]: ${message}\n`
//   fs.appendFileSync('logs/app.log', logMessage)
}

// Custom logger interface
interface Logger {
  error: (message: string, error?: unknown) => void
  info: (message: string) => void
  debug: (message: string) => void
  customDebug: (message: string, object?: unknown) => void
  success: (message: string) => void
  warn: (message: string) => void
}

// Custom logger implementation
const CustomLogger: Logger = {
  error: (message, error) => {
    const formattedMessage = `[ERROR] ${getTimestamp()}: ${message}`.red
    console.error(formattedMessage, error instanceof Object ? error : String(error).red)
    logToFile(`${formattedMessage} : ${JSON.stringify(error)}`, 'error')
  },
  info: (message) => {
    const formattedMessage = `[INFO] ${getTimestamp()}: ${message}`.magenta
    console.info(formattedMessage)
    logToFile(formattedMessage, 'info')
  },
  debug: (message) => {
    const formattedMessage = `[DEBUG] ${getTimestamp()}: ${message}`.blue
    console.debug(formattedMessage)
    logToFile(formattedMessage, 'debug')
  },
  customDebug: (message, object) => {
    const formattedMessage = `[DEBUG] ${getTimestamp()}: ${message}`.blue
    console.debug(formattedMessage, object ?? '')
    logToFile(formattedMessage, 'debug')
  },
  success: (message) => {
    const formattedMessage = `[SUCCESS] ${getTimestamp()}: ${message}`.green
    console.log(formattedMessage)
    logToFile(formattedMessage, 'success')
  },
  warn: (message) => {
    const formattedMessage = `[WARN] ${getTimestamp()}: ${message}`.yellow
    console.warn(formattedMessage)
    logToFile(formattedMessage, 'warn')
  },
}

export default CustomLogger
