import React, {useState} from 'react'
import {KTCardBody} from '../../../../_metronic/helpers/components/KTCardBody'
import PluginData from '../plugins/PluginsData'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import ProfileQuestionsAndInterest from './Componants/ProfileQuestionsAndInterest/ProfileQuestionsAndInterest'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Link, Route, Routes, useLocation} from 'react-router-dom'
import Gifts from './Componants/Gifts/gifts'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import ChatPlugin from './Componants/Chat/ChatPlugin'
import SitePricingPlugin from './Componants/SitePricing/SitePricingPlugin'
import RewardPlugin from './Componants/Reward/RewardPlugin'
import GlobalOnlinePlugin from './Componants/GlobalOnline/globalOnlinePlugin'
import SpotlightPlugin from './Componants/Spotlight/SpotlightPlugin'
import VideocallPlugin from './Componants/Videocall/VideocallPlugin'
import LegalInformationPlugin from './Componants/LegalInformation/LegalInformationPlugin'
import DiscoverGame from './Componants/DiscoverGame/DiscoverGamePlugin'
import GeneralPlugin from './Componants/General/GeneralPlugin'
import VerificationSystemPlugin from './Componants/VerificationSystem/VerificationSystemPlugin'
import ManualPaymentGatewaysPlugin from './Componants/ManualPaymentGateways/ManualPaymentGatewaysPlugin'
import StripePaymentPlugin from './Componants/StripePayment/StripePaymentPlugin'
import RazorpayPaymentPlugin from './Componants/RazorpayPayment/RazorpayPaymentPlugin'
import PhonePayPlugin from './Componants/PhonePay/PhonePayPlugins'
import FolderStructurePlugin from './Componants/FolderStructure/folderStrucuturePlugin'
import AnonymousUserInteraction from './Componants/AnonymousUserInteraction/AnonymousUserInteraction'
import WithDrawalPlugin from './Componants/WithDrawal/withdrawalPlugin'
import SmtpPlugin from './Componants/Smtp/SmtpPlugin'
import AdsPlugin from './Componants/AdsKey/AdsPlugin'
import StoryPlugin from './Componants/Story/StoryConfig'
import AdsPlanPlugin from './Componants/AdsPlanner/AdsPlanPlugin'
import NotificationPlugin from './Componants/Notification/NotificationPlugin'
import MegaChatBotPlugin from './Componants/MegaChatBot/MegaChatBotPlugin'
import LiveSteamingPlugin from './Componants/LiveStreaming/LiveSteamingPlugin'
import CurrencyRatePlugin from './Componants/CurrencyRate/CurrencyRatePlugin'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Plugins',
    path: 'plugins',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Plugins = () => {
  const intl = useIntl()
  const location = useLocation()
  const [pluginList, setPluginList] = useState(PluginData)

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {intl.formatMessage({id: 'MENU.PLUGINS'})}
      </PageTitle>
      {location.pathname === '/plugins' && (
        <KTCardBody className='py-4 card'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <td colSpan={4}>{intl.formatMessage({id: 'PLUGINS.TABLE.PLUGINNAME'})}</td>
                  <td colSpan={4}>{intl.formatMessage({id: 'PLUGINS.TABLE.DESCRIPTION'})}</td>
                  <td colSpan={2}>{intl.formatMessage({id: 'PLUGINS.TABLE.STATUS'})}</td>
                  <td colSpan={2}>{intl.formatMessage({id: 'PLUGINS.TABLE.ACTIONS'})}</td>
                </tr>
              </thead>
              <tbody className='text-gray-600 '>
                {pluginList.map((plugin) => {
                  return (
                    <tr>
                      <td colSpan={4}>
                        <div className='d-flex align-items-center '>
                          <div className='symbol symbol-50px overflow-visible me-3'>
                            <img
                              src={
                                toAbsoluteUrl(plugin.icon) ||
                                `https://preview.keenthemes.com/metronic8/react/demo1//media/avatars/300-6.jpg`
                              }
                              alt='icon'
                              width='50px'
                              height='50px'
                              loading='lazy'
                            />
                          </div>

                          <div className='flex-grow-1'>
                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-4'>
                              {plugin.name}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-6'>
                              {plugin.type}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td colSpan={4}>
                        <div className='text-muted fw-semibold fs-6'>{plugin.description}</div>
                      </td>
                      <td colSpan={2}>
                        <span
                          className={clsx('badge rounded-pill  ', {
                            'text-bg-success text-white': plugin.status === 'Active',
                            'text-bg-danger text-white': plugin.status === 'Disabled',
                          })}
                        >
                          {plugin.status}
                        </span>
                      </td>
                      <td colSpan={2}>
                        <Link to={`/plugins/${plugin.path}`}>
                          <button className='btn btn-sm btn-light' data-kt-menu-dismiss='true'>
                            <i className='fa-solid fa-gear'></i>
                            Setting
                          </button>
                        </Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </KTCardBody>
      )}

      <Routes>
        <Route
          path='/question_answer_plugin'
          element={
            <>
              <PageTitle>Questions and Answers</PageTitle>
              <ProfileQuestionsAndInterest />
            </>
          }
        />
        <Route
          path='/gifts_plugin'
          element={
            <>
              <PageTitle>Gifts</PageTitle>
              <Gifts />
            </>
          }
        />
        <Route
          path='/chat_plugin'
          element={
            <>
              <PageTitle>Chat</PageTitle>
              <ChatPlugin />
            </>
          }
        />
        <Route
          path='/site_pricing_plugin'
          element={
            <>
              <PageTitle>Site Pricing</PageTitle>
              <SitePricingPlugin />
            </>
          }
        />

        <Route
          path='/reward_plugin'
          element={
            <>
              <PageTitle>Reward</PageTitle>
              <RewardPlugin />
            </>
          }
        />
        <Route
          path='/global_online_plugin'
          element={
            <>
              <PageTitle>Global Online</PageTitle>
              <GlobalOnlinePlugin />
            </>
          }
        />
        <Route
          path='/spotlight_plugin'
          element={
            <>
              <PageTitle>Spotlight</PageTitle>
              <SpotlightPlugin />
            </>
          }
        />
        <Route
          path='/videocall_plugin'
          element={
            <>
              <PageTitle>Videocall</PageTitle>
              <VideocallPlugin />
            </>
          }
        />
        <Route
          path='/discover_game_plugin'
          element={
            <>
              <PageTitle>Discover Game</PageTitle>
              <DiscoverGame />
            </>
          }
        />
        <Route
          path='/general_plugin'
          element={
            <>
              <PageTitle>General</PageTitle>
              <GeneralPlugin />
            </>
          }
        />
        <Route
          path='/verification_system_plugin'
          element={
            <>
              <PageTitle>Verification System</PageTitle>
              <VerificationSystemPlugin />
            </>
          }
        />

        <Route
          path='/legal_information_plugin'
          element={
            <>
              <PageTitle>Legal Information</PageTitle>
              <LegalInformationPlugin />
            </>
          }
        />
        <Route
          path='/manual_payment_gateways_plugin'
          element={
            <>
              <PageTitle>Manual Payment Gateways</PageTitle>
              <ManualPaymentGatewaysPlugin />
            </>
          }
        />
        <Route
          path='/stripe_payment_plugin'
          element={
            <>
              <PageTitle>Stripe Payment Gateways</PageTitle>
              <StripePaymentPlugin />
            </>
          }
        />
        <Route
          path='/razor_pay_payment_plugin'
          element={
            <>
              <PageTitle>Razor Pay Payment Gateways</PageTitle>
              <RazorpayPaymentPlugin />
            </>
          }
        />
        <Route
          path='/phone_pay_payment_plugin'
          element={
            <>
              <PageTitle>Phone Pay Payment Gateways</PageTitle>
              <PhonePayPlugin />
            </>
          }
        />
        <Route
          path='/folder_structure_plugin'
          element={
            <>
              <PageTitle>Folder Structure Setting</PageTitle>
              <FolderStructurePlugin />
            </>
          }
        />
        <Route
          path='/user_interaction_plugin'
          element={
            <>
              <PageTitle>Anonymous User Interaction</PageTitle>
              <AnonymousUserInteraction />
            </>
          }
        />
        <Route
          path='/withdrawal_plugin'
          element={
            <>
              <PageTitle>Withdrawal Plugin</PageTitle>
              <WithDrawalPlugin />
            </>
          }
        />
        <Route
          path='/smtp_plugin'
          element={
            <>
              <PageTitle>SMTP Plugin</PageTitle>
              <SmtpPlugin />
            </>
          }
        />
        <Route
          path='/ads_plugin'
          element={
            <>
              <PageTitle>Ads Plugin</PageTitle>
              <AdsPlugin />
            </>
          }
        />
        <Route
          path='/story_plugin'
          element={
            <>
              <PageTitle>Story Plugin</PageTitle>
              <StoryPlugin />
            </>
          }
        />
        <Route
          path='/ads_plan_plugin'
          element={
            <>
              <PageTitle>Ads Plan Plugin</PageTitle>
              <AdsPlanPlugin />
            </>
          }
        />
        <Route
          path='/notification_plugin'
          element={
            <>
              <PageTitle>Notification Plugin</PageTitle>
              <NotificationPlugin />
            </>
          }
        />
        <Route
          path='/mega_chat_bot_plugin'
          element={
            <>
              <PageTitle>Mega Chat Bot Plugin</PageTitle>
              <MegaChatBotPlugin />
            </>
          }
        />
        {/* <Route
          path='/moderator_level_system'
          element={
            <>
              <PageTitle>Moderator Level System</PageTitle>
              <ModeratorLevelSystemPlugin />
            </>
          }
        /> */}
        <Route
          path='/live_streaming_plugin'
          element={
            <>
              <PageTitle>Live Streaming</PageTitle>
              <LiveSteamingPlugin />
            </>
          }
        />
        <Route
          path='/currency_rate_plugin'
          element={
            <>
              <PageTitle>Currency Rate</PageTitle>
              <CurrencyRatePlugin />
            </>
          }
        />
      </Routes>
    </>
  )
}

export default Plugins
