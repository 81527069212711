import React, {useEffect, useState} from 'react'
import DatePicker from '../../../../_metronic/partials/componants/DatePicker'

const ModeratorList = (props) => {
  let {
    premiumPlanFilterCount,
    creditPlanFilterCount,
    handleModelChange,
    moderatorsFilter,
    setModeratorsFilter,
    moderatorDetailsList,
  } = props

  return (
    <div className='table-responsive card p-5'>
      <div className='d-flex justify-content-end'>
        <div onClick={() => handleModelChange(true)} className='me-3 mt-3'>
          <i className='fa-solid fa-filter fa-2xl'></i>
          {premiumPlanFilterCount + creditPlanFilterCount > 0 && (
            <span> ({premiumPlanFilterCount + creditPlanFilterCount})</span>
          )}
        </div>
        <div>
          <DatePicker
            selectionRange={moderatorsFilter}
            setSelectionRange={setModeratorsFilter}
            isAddDayView={false}
            isToggleShow={false}
          />
        </div>
      </div>
      <div className='divider'></div>
      <table
        id='kt_table_users'
        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer h-500 overflow-scroll'
      >
        <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            <td>Moderator Name</td>
            <td className='text-center'>Users</td>
            <td className='text-center'>New Premium</td>
            <td className='text-center'>Re. Premium</td>
            <td className='text-center'>New Credits</td>
            <td className='text-center'>Re. Credits</td>
            <td className='text-center'>Total Premium</td>
            <td className='text-center'>Total Credits</td>
          </tr>
        </thead>
        <tbody className='text-gray-600 '>
          {moderatorDetailsList !== undefined &&
            moderatorDetailsList.map((moderator: any) => {
              return (
                <tr>
                  <td style={{color: moderator?.moderatorStatus ? 'green' : 'red'}}>
                    {moderator?.moderatorName}
                  </td>
                  <td className='text-center'>{moderator?.userCount}</td>
                  <td className='text-center'>{moderator?.premiumNewCount}</td>
                  <td className='text-center'>{moderator?.premiumRecurringCount}</td>
                  <td className='text-center'>{moderator?.creditNewCount}</td>
                  <td className='text-center'>{moderator?.creditRecurringCount}</td>
                  <td className='text-center'>
                    {parseInt(moderator?.premiumNewCount) +
                      parseInt(moderator?.premiumRecurringCount)}
                  </td>
                  <td className='text-center'>
                    {parseInt(moderator?.creditNewCount) +
                      parseInt(moderator?.creditRecurringCount)}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default ModeratorList
