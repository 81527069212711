import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

const CardWidget = (props: any) => {
  const {count, title, percentage, isPercentageShow, icon} = props
  return (
    <div className={`card card-flush`}>
      <div className='card-title p-5'>
        <div className='row'>
          <div className='col-8'>
            <div className='d-flex align-content-center'>
              <span className='fs-1 fw-bold text-dark me-2 lh-1 ls-n2'>{count}</span>
              {isPercentageShow &&
                (percentage < 0 ? (
                  <span className='badge badge-light-danger fs-base ms-2'>
                    <KTIcon iconName='arrow-down' className='fs-5 text-danger ms-n1' />
                    {percentage !== null ? Math.abs(percentage) : 0}%
                  </span>
                ) : (
                  <span className='badge badge-light-success fs-base ms-2'>
                    <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' />{' '}
                    {percentage !== null ? percentage : 0}%
                  </span>
                ))}
            </div>
            <span className='text-gray-400 pt-1 fw-semibold fs-6'>{title}</span>
          </div>
          <div className='col-4 d-flex justify-content-center align-items-center'>{icon}</div>
        </div>
      </div>
    </div>
  )
}

export default CardWidget
