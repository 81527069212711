import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {DateRangePicker, defaultStaticRanges} from 'react-date-range'
import {isSameDay, subDays, format} from 'date-fns'
import {getDaysBetweenTwoDate} from '../../../utils/DateUtils'
import moment from 'moment'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DatePicker = (props) => {
  let {selectionRange, setSelectionRange, isAddDayView, isToggleShow, isToggled, setIsToggled} =
    props

  const [modalShow, setModalShow] = useState(false)

  const handleToggle = () => {
    setIsToggled(!isToggled)
  }

  const handleSelect = (ranges: any) => {
    let duration: any, view: any, day: any

    let startDate = ranges.selection.startDate
    let endDate = ranges.selection.endDate

    day = getDaysBetweenTwoDate(startDate, endDate)
    day = Math.round(day)

    let yesterday = new Date(new Date())

    yesterday.setDate(yesterday.getDate() - 1)

    if (new Date(startDate).toLocaleDateString() == new Date(endDate).toLocaleDateString()) {
      if (new Date(startDate).toLocaleDateString() == new Date().toLocaleDateString()) {
        day = day >= 0 && day <= 1 ? 'today' : day
      } else if (new Date(startDate).toLocaleDateString() == yesterday.toLocaleDateString()) {
        day = day >= 0 && day <= 1 ? 'yesterday' : day
      } else {
        day = parseInt(day)
      }
    }

    switch (day) {
      case 'today':
      case 'yesterday':
        duration = day
        view = 'hour'
        day = day
        break
      case 0:
        duration = 'custom'
        view = 'hour'
        day = parseInt(day)
        break
      case 6:
      case 7:
      case 31:
      case 30:
      case 29:
      case 90:
        duration = 'custom'
        view = 'day'
        day = parseInt(day)
        break
      default:
        duration = 'custom'
        view = 'day'
        day = parseInt(day)
    }

    startDate = new Date(startDate).toISOString()
    endDate = new Date(endDate).toISOString()

    //console.log(startDate, endDate)

    // startDate = startDate.toISOString()
    // endDate = endDate.toISOString()

    //if (isToggled) {
    if (isAddDayView) {
      setSelectionRange({
        ...selectionRange,
        startDate,
        endDate,
        duration,
        view,
        day,
      })
    } else {
      setSelectionRange({
        ...selectionRange,
        startDate,
        endDate,
      })
    }
    //}
  }

  return (
    <div>
      {isToggleShow && (
        <label className='form-label fs-6 fw-bold d-flex justify-content-between'>
          Select Date
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckDefault'
              name='isWebSocketEnabled'
              onChange={handleToggle}
              checked={isToggled}
            />
          </div>
        </label>
      )}
      <input
        placeholder='Register Date'
        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
        autoComplete='off'
        value={`${moment(selectionRange.startDate).format('DD/MM/YYYY')} - ${moment(
          selectionRange.endDate
        ).format('DD/MM/YYYY')}`}
        onClick={() => setModalShow(true)}
        readOnly
        disabled={isToggleShow ? !isToggled : false}
      />
      <Modal show={modalShow} onHide={() => setModalShow(false)} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRangePicker
            onChange={handleSelect}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            style={{width: 250}}
            ranges={[
              {
                startDate: selectionRange.startDate,
                endDate: selectionRange.endDate,
                key: 'selection',
              },
            ]}
            direction='horizontal'
            inputRanges={[]}
            showPreview={false}
            staticRanges={[
              ...defaultStaticRanges,
              {
                label: 'Last 90 days',
                range: () => ({
                  startDate: subDays(new Date(), 90),
                  endDate: new Date(),
                }),
                isSelected(range: any) {
                  const definedRange = this.range()
                  return (
                    isSameDay(selectionRange.startDate, definedRange.startDate) &&
                    isSameDay(selectionRange.endDate, definedRange.endDate)
                  )
                },
              },
              {
                label: 'Custom Range',
                range: () => ({
                  startDate: new Date(),
                  endDate: new Date(),
                }),
                isSelected(range) {
                  const definedRange = this.range()
                  return (
                    isSameDay(selectionRange.startDate, definedRange.startDate) &&
                    isSameDay(selectionRange.endDate, definedRange.endDate)
                  )
                },
              },
            ]}
          />
          {/* <div className='d-flex flex-end'>
            <button className='btn btn-primary btn-sm' onClick={() => onSubmit()}>
              Apply
            </button>
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DatePicker
