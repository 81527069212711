import React, {useEffect, useState} from 'react'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'

const AdsPlugin = () => {
  const [configID, setConfigId] = useState(0)
  const [adsConfig, setAdsConfig] = useState<any>({
    isAdEnabled: false,
    banner: '',
    interstitial: '',
    appOpen: '',
    nativeAdvanced: '',
    rewarded: '',
    publisherId: '',
    appId: '',
  })

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    if (name !== 'isAdEnabled') {
      setAdsConfig({...adsConfig, [name]: event.target.value})
    } else {
      setAdsConfig({...adsConfig, [name]: value})
      updateConfiguration({...adsConfig, [name]: value})
    }
  }

  const onBlurConfigUpdate = () => {
    updateConfiguration(adsConfig)
  }

  const getConfiguration = async () => {
    let result = await getConfigurationByName('ads_key')

    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigId(result.data.id)
      setAdsConfig({
        isAdEnabled: parsedData?.isAdEnabled,
        banner: parsedData?.banner,
        interstitial: parsedData?.interstitial,
        appOpen: parsedData?.appOpen,
        nativeAdvanced: parsedData?.nativeAdvanced,
        rewarded: parsedData?.rewarded,
        publisherId: parsedData?.publisherId,
        appId: parsedData?.appId,
      })
    }
  }

  const updateConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-title p-6'>
        <h2>Ads settings</h2>
      </div>
      <div className='ps-10 pb-8 pe-8'>
        <div className='row no-gutters'>
          <div className='col-lg-4 p-2 bg-light'>
            <p>
              <strong className='headings-color'>Enable Ads</strong>
            </p>
            <p className='text-muted'>Enable or disable Ads</p>
          </div>
          <div className='col-lg-8 card-form__body p-2 d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isAdEnabled'
                checked={adsConfig.isAdEnabled}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 p-2 bg-light'>
            <p>
              <strong className='headings-color'>Banner Ad Key</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body p-2  bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='banner'
                value={adsConfig.banner}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurConfigUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 p-2 bg-light'>
            <p>
              <strong className='headings-color'>Interstitial Ad Key</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body p-2  bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='interstitial'
                value={adsConfig.interstitial}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurConfigUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 p-2 bg-light'>
            <p>
              <strong className='headings-color'>App Open Ad Key</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body p-2  bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='appOpen'
                value={adsConfig.appOpen}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurConfigUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 p-2 bg-light'>
            <p>
              <strong className='headings-color'>Native Advanced Ad Key</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body p-2  bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='nativeAdvanced'
                value={adsConfig.nativeAdvanced}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurConfigUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 p-2 bg-light'>
            <p>
              <strong className='headings-color'>Rewarded Ad Key</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body p-2  bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='rewarded'
                value={adsConfig.rewarded}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurConfigUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 p-2 bg-light'>
            <p>
              <strong className='headings-color'>Publisher Id</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body p-2  bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='publisherId'
                value={adsConfig.publisherId}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurConfigUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 p-2 bg-light'>
            <p>
              <strong className='headings-color'>App Id</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body p-2  bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='appId'
                value={adsConfig.appId}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurConfigUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdsPlugin
