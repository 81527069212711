/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useCallback} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {
  calculateTimeDifferenceForActivity,
  calculateTimeDifferenceForChatMessage,
} from '../../../../utils/DateUtils'
import clsx from 'clsx'
import {
  getConfigurationByName,
  getDashboardActivityWithPagination,
  updateConfigurationByConfigID,
} from '../../../../API/api-endpoint'
import CustomPagination from '../../../../_metronic/partials/componants/Pagination'
import {Link} from 'react-router-dom'
import {getRouteForProfile} from '../../../../utils/Utils'

const ActivityTable = (props: any) => {
  let {isWebSocketEnabled, setIsWebSocketEnabled, webSocketActivity} = props

  const [tabValue, setTabValue] = useState('all')
  const [mainTabValue, setMainTabValue] = useState('all-user')
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [activityList, setActivityList] = useState(isWebSocketEnabled ? webSocketActivity : [])
  const [configID, setConfigId] = useState(0)
  const [generalSetting, setGeneralSetting] = useState({})

  const renderMessageContent = (messageType: any, activity: any) => {
    switch (messageType) {
      case 'text':
        return (
          <div>
            <Link
              to={getRouteForProfile(
                activity?.senderDetail?.registerFrom,
                activity?.senderDetail?.userId
              )}
            >
              <span style={{color: '#2F78EE'}}>{activity?.senderDetail?.userName + ' '}</span>
            </Link>
            <span>send message to</span>
            <Link
              to={getRouteForProfile(
                activity?.receiverDetail?.registerFrom,
                activity?.receiverDetail?.userId
              )}
            >
              <span style={{color: '#2F78EE'}}>{' ' + activity?.receiverDetail?.userName}</span>
            </Link>
            <div>{activity?.message}</div>
          </div>
        )
      case 'gift':
        return (
          <div>
            <Link
              to={getRouteForProfile(
                activity?.senderDetail?.registerFrom,
                activity?.senderDetail?.userId
              )}
            >
              <span style={{color: '#2F78EE'}}>{activity?.senderDetail?.userName + ' '}</span>
            </Link>

            <span>send gift to</span>
            <Link
              to={getRouteForProfile(
                activity?.receiverDetail?.registerFrom,
                activity?.receiverDetail?.userId
              )}
            >
              <span style={{color: '#2F78EE'}}>{' ' + activity?.receiverDetail?.userName}</span>
            </Link>
          </div>
        )
      case 'media':
        return (
          <div>
            <Link
              to={getRouteForProfile(
                activity?.senderDetail?.registerFrom,
                activity?.senderDetail?.userId
              )}
            >
              <span style={{color: '#2F78EE'}}>{activity?.senderDetail?.userName + ' '}</span>
            </Link>
            <span>send media to </span>
            <Link
              to={getRouteForProfile(
                activity?.receiverDetail?.registerFrom,
                activity?.receiverDetail?.userId
              )}
            >
              <span style={{color: '#2F78EE'}}>{' ' + activity?.receiverDetail?.userName}</span>
            </Link>
          </div>
        )
      case 'videocall':
        return (
          <div>
            <Link
              to={getRouteForProfile(
                activity?.senderDetail?.registerFrom,
                activity?.senderDetail?.userId
              )}
            >
              <span style={{color: '#2F78EE'}}>{activity?.senderDetail?.userName + ' '}</span>
            </Link>

            <span>{activity?.message}</span>
            <Link
              to={getRouteForProfile(
                activity?.receiverDetail?.registerFrom,
                activity?.receiverDetail?.userId
              )}
            >
              <span style={{color: '#2F78EE'}}>{' ' + activity?.receiverDetail?.userName}</span>
            </Link>
          </div>
        )
      default:
        return null
    }
  }

  const renderDisplayMessage = (type: any, activity: any, index: any) => {
    switch (type) {
      case 'chat':
        return (
          <tr key={index}>
            <td className='d-flex'>
              <span className='badge badge-secondary me-2'>
                {activity?.senderDetail?.registerFrom !== 'app' ? 'A' : ''}
              </span>
              <span className='badge' style={{backgroundColor: '#DDF5FF', color: '#87CEEB'}}>
                chat
              </span>
            </td>

            <td>{renderMessageContent(activity.type, activity)}</td>

            <td className='pe-0 text-end min-w-50px'>
              {calculateTimeDifferenceForChatMessage(activity?.createdAt)}
            </td>

            <td className='text-end'>
              <Link
                to={getRouteForProfile(
                  activity?.senderDetail?.registerFrom,
                  activity?.senderDetail?.userId
                )}
              >
                <KTIcon iconName='arrow-right' className='fs-2' />
              </Link>
            </td>
          </tr>
        )
      case 'like':
        return (
          <tr key={index}>
            <td className='d-flex'>
              <span className='badge badge-secondary me-2'>
                {activity?.userDetail?.registerFrom !== 'app' ? 'A' : ''}
              </span>
              <span className='badge' style={{backgroundColor: '#FFEEF3', color: '#F8285A'}}>
                like-profile
              </span>
            </td>

            <td>
              <Link
                to={getRouteForProfile(
                  activity?.userDetail?.registerFrom,
                  activity?.userDetail?.userId
                )}
              >
                <span style={{color: '#2F78EE'}}>{activity?.userDetail?.userName + ' '}</span>
              </Link>
              <span>liked</span>
              <Link
                to={getRouteForProfile(
                  activity?.likedUserDetail?.registerFrom,
                  activity?.likedUserDetail?.userId
                )}
              >
                <span style={{color: '#2F78EE'}}>{' ' + activity?.likedUserDetail?.userName}</span>
              </Link>
            </td>

            <td className='pe-0 text-end min-w-50px'>
              {calculateTimeDifferenceForChatMessage(activity?.createdAt)}
            </td>
            <td className='text-end'>
              <Link
                to={getRouteForProfile(
                  activity?.userDetail?.registerFrom,
                  activity?.userDetail?.userId
                )}
              >
                <KTIcon iconName='arrow-right' className='fs-2' />
              </Link>
            </td>
          </tr>
        )
      case 'visit':
        return (
          <tr key={index}>
            <td className='d-flex'>
              <span className='badge badge-secondary me-2'>
                {activity?.userData?.registerFrom !== 'app' ? 'A' : ''}
              </span>
              <span className='badge' style={{backgroundColor: '#DFFFEA', color: '#17C653'}}>
                visit-profile
              </span>
            </td>

            <td>
              <Link
                to={getRouteForProfile(
                  activity?.userData?.registerFrom,
                  activity?.userData?.userId
                )}
              >
                <span style={{color: '#2F78EE'}}>{activity?.userData?.userName + ' '}</span>
              </Link>
              <span>visited</span>
              <Link
                to={getRouteForProfile(
                  activity?.receiverData?.registerFrom,
                  activity?.receiverData?.userId
                )}
              >
                <span style={{color: '#2F78EE'}}>{' ' + activity?.receiverData?.userName}</span>
              </Link>
            </td>

            <td className='pe-0 text-end min-w-50px'>
              {calculateTimeDifferenceForChatMessage(activity?.createdAt)}
            </td>
            <td className='text-end'>
              <Link
                to={getRouteForProfile(
                  activity?.userData?.registerFrom,
                  activity?.userData?.userId
                )}
              >
                <KTIcon iconName='arrow-right' className='fs-2' />
              </Link>
            </td>
          </tr>
        )
      case 'match':
        return (
          <tr key={index}>
            <td className='d-flex'>
              <span className='badge badge-secondary me-2'>
                {activity?.userDetail?.registerFrom !== 'app' ? 'A' : ''}
              </span>
              <span className='badge' style={{backgroundColor: '#FFD6D2', color: '#FF4330'}}>
                match
              </span>
            </td>

            <td>
              <Link
                to={getRouteForProfile(
                  activity?.userDetail?.registerFrom,
                  activity?.userDetail?.userId
                )}
              >
                <span style={{color: '#2F78EE'}}>{activity?.userDetail?.userName + ' '}</span>
              </Link>
              <span>profile match with</span>
              <Link
                to={getRouteForProfile(
                  activity?.likedUserDetail?.registerFrom,
                  activity?.likedUserDetail?.userId
                )}
              >
                <span style={{color: '#2F78EE'}}>{' ' + activity?.likedUserDetail?.userName}</span>
              </Link>
            </td>

            <td className='pe-0 text-end min-w-50px'>
              {calculateTimeDifferenceForChatMessage(activity?.createdAt)}
            </td>
            <td className='text-end'>
              <Link
                to={getRouteForProfile(
                  activity?.userDetail?.registerFrom,
                  activity?.userDetail?.userId
                )}
              >
                <KTIcon iconName='arrow-right' className='fs-2' />
              </Link>
            </td>
          </tr>
        )
      case 'system':
        return (
          <tr key={index}>
            <td>
              <div className='badge' style={{backgroundColor: '#E9F3FF', color: '#1B84FF'}}>
                system
              </div>
            </td>

            <td>
              <Link
                to={getRouteForProfile(
                  activity?.userData?.registerFrom,
                  activity?.userData?.userId
                )}
              >
                <span style={{color: '#2F78EE'}}>{activity?.userData?.userName + ' '}</span>
              </Link>

              <span>{activity?.description + ' '}</span>
            </td>

            <td className='pe-0 text-end min-w-50px'>
              {calculateTimeDifferenceForChatMessage(activity?.createdAt)}
            </td>
            <td className='text-end'>
              <a href='#' className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                <Link
                  to={getRouteForProfile(
                    activity?.userData?.registerFrom,
                    activity?.userData?.userId
                  )}
                >
                  <KTIcon iconName='arrow-right' className='fs-2' />
                </Link>
              </a>
            </td>
          </tr>
        )
      case 'purchase':
        return (
          <tr key={index}>
            <td className='d-flex'>
              <span className='badge badge-secondary me-2'>
                {activity?.userDetail?.registerFrom !== 'app' ? 'A' : ''}
              </span>
              <span className='badge' style={{backgroundColor: '#EEE6FF', color: '#7239EA'}}>
                Purchase
              </span>
            </td>

            <td>
              <Link
                to={getRouteForProfile(
                  activity?.userDetail?.registerFrom,
                  activity?.userDetail?.userId
                )}
              >
                <span style={{color: '#2F78EE'}}>{activity?.userDetail?.userName + ' '}</span>
              </Link>
              <span>
                {activity?.creditPackageAmountId !== null
                  ? activity?.description
                  : `purchase ${activity?.premiumPackageAmountDetail?.premiumPackageName} plan`}
                (₹{activity?.amount})
              </span>
            </td>

            <td className='pe-0 text-end min-w-50px'>
              {calculateTimeDifferenceForChatMessage(activity?.createdAt)}
            </td>
            <td className='text-end'>
              <Link
                to={getRouteForProfile(
                  activity?.userDetail?.registerFrom,
                  activity?.userDetail?.userId
                )}
              >
                <KTIcon iconName='arrow-right' className='fs-2' />
              </Link>
            </td>
          </tr>
        )
      case 'referrals':
        return (
          <tr key={index}>
            <td className='d-flex'>
              <span className='badge badge-secondary me-2'>
                {activity?.registerFrom !== 'app' ? 'A' : ''}
              </span>
              <div className='badge' style={{backgroundColor: '#D9FEFE', color: '#4CFFFF'}}>
                Referrals
              </div>
            </td>

            <td>
              <Link to={getRouteForProfile(activity?.registerFrom, activity?.userId)}>
                <span style={{color: '#2F78EE'}}>{activity?.userName + ' '}</span>
              </Link>
              <span>Referred by</span>
              <Link to={getRouteForProfile(activity.refRegisterFrom, activity.refUserId)}>
                <span style={{color: '#2F78EE'}}>{' ' + activity?.refUserName}</span>
              </Link>
            </td>

            <td className='pe-0 text-end min-w-50px'>
              {calculateTimeDifferenceForChatMessage(activity?.createdAt)}
            </td>
            <td className='text-end'>
              <Link to={getRouteForProfile(activity?.registerFrom, activity.userId)}>
                <KTIcon iconName='arrow-right' className='fs-2' />
              </Link>
            </td>
          </tr>
        )

      default:
        return null
    }
  }

  const handleChange = (tabName: any) => {
    setTabValue(tabName)
  }

  useEffect(() => {
    if (!isWebSocketEnabled) {
      if (tabValue === 'all') {
        getActivitiesList(page, pageSize, 'all')
      } else {
        getActivitiesList(page, pageSize, tabValue)
      }
    }
  }, [tabValue, isWebSocketEnabled])

  useEffect(() => {
    getGeneralConfig()
  }, [])

  useEffect(() => {
    const filterActivityList = (list, tab) => {
      return list.filter((item) => {
        if (tab !== 'all' && item.activityType !== tab) {
          return false
        }
        if (item.activityType === 'visit' && item.userData?.registerFrom === 'anonyms') {
          return true
        }
        if (
          ['undo', 'like', 'dislike', 'match', 'purchase'].includes(item.activityType) &&
          item.userDetail?.registerFrom === 'anonyms'
        ) {
          return true
        }
        if (item.activityType === 'chat' && item.senderDetail?.registerFrom === 'anonyms') {
          return true
        }
        return false
      })
    }

    if (mainTabValue === 'all-user') {
      // if (tempActivityList.length !== 0) {
      //   setActivityList(tempActivityList)
      // }
      if (tabValue === 'all') {
        setActivityList(webSocketActivity)
      } else {
        const filteredActivity = webSocketActivity.filter(
          (activity) => activity.activityType === tabValue
        )
        console.log(filteredActivity)
        setActivityList(filteredActivity)
      }
    } else {
      const filteredData = filterActivityList(webSocketActivity, tabValue)

      // Update only if filtered activities are different
      if (filteredData.length !== webSocketActivity.length) {
        setActivityList(filteredData)
      }
    }
  }, [webSocketActivity, mainTabValue, tabValue])

  const getActivitiesList = async (page: number, pageSize: number, type: any) => {
    let result = await getDashboardActivityWithPagination(page, pageSize, type)
    if (result.status === 200) {
      setActivityList(result.data)
      setTotalPage(result?.totalPage)
    }
  }

  const getPagination = (page: any, pageSize: any) => {
    if (page === 0 || page === 1) {
      page = 1
    }
    setPage(page)
    let type = tabValue === 'all' ? '' : tabValue
    getActivitiesList(page, pageSize, type)
  }

  const getGeneralConfig = async () => {
    let result = await getConfigurationByName('general_setting')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigId(result.data.id)
      setIsWebSocketEnabled(parsedData.isWebSocketEnabled)
      setGeneralSetting({
        isWebSocketEnabled: parsedData.isWebSocketEnabled,
        isInAppPurchaseEnabled: parsedData?.isInAppPurchaseEnabled,
        isManualPurchaseEnabled: parsedData?.isManualPurchaseEnabled,
        isProfileImageRequired: parsedData?.isProfileImageRequired,
      })
    }
  }

  const handleChangeWebSocket = (event: any) => {
    setIsWebSocketEnabled(event.target.checked)
    let updatedConfig = {
      ...generalSetting,
      isWebSocketEnabled: event.target.checked,
    }

    setGeneralSetting(updatedConfig)
    updateConfiguration(updatedConfig)
  }

  const updateConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getGeneralConfig()
    }
  }

  return (
    <div className={`card`}>
      <div className='d-flex mt-5 justify-content-around fs-5 fw-bolder d-flex'>
        <div
          className={clsx('nav-item nav-link text-muted me-6 cursor-pointer', {
            active: mainTabValue === 'all-user',
            'text-active-primary': mainTabValue === 'all-user',
          })}
          onClick={() => {
            setMainTabValue('all-user')
          }}
        >
          All Activity
        </div>
        <div
          className={clsx('nav-item nav-link text-muted me-6 cursor-pointer', {
            active: mainTabValue === 'anonymous-user',
            'text-active-primary': mainTabValue === 'anonymous-user',
          })}
          onClick={() => {
            setMainTabValue('anonymous-user')
          }}
        >
          Anonymous User Activity
        </div>
      </div>
      <hr className='text-muted'></hr>
      <div className='card-header border-1'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Activity</span>
        </h3>
        <div>
          <div className='card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                Websocket On
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isWebSocketEnabled'
                checked={isWebSocketEnabled}
                onChange={(event) => handleChangeWebSocket(event)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-toolbar mt-2 d-flex justify-content-center'>
        <ul className='nav'>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
              data-bs-toggle='tab'
              onClick={() => handleChange('all')}
            >
              All
            </a>
          </li>

          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
              onClick={() => handleChange('visit')}
            >
              Visit
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
              onClick={() => handleChange('like')}
            >
              Like
            </a>
          </li>

          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
              onClick={() => handleChange('match')}
            >
              Match
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
              onClick={() => handleChange('dislike')}
            >
              DisLike
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
              onClick={() => handleChange('chat')}
            >
              Chat
            </a>
          </li>
          {/* <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
            >
              VideoCall
            </a>
          </li> */}
          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
              onClick={() => handleChange('undo')}
            >
              Undo
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
              onClick={() => handleChange('purchase')}
            >
              Purchase
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
              data-bs-toggle='tab'
              onClick={() => handleChange('referrals')}
            >
              Referrals
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
              data-bs-toggle='tab'
              onClick={() => handleChange('system')}
            >
              System
            </a>
          </li>
        </ul>
      </div>

      <div className='card-body py-3' style={{height: '450px', overflowY: 'scroll'}}>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-280px'></th>
                    <th className='p-0 min-w-20px'></th>
                    <th className='p-0 min-w-20px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {activityList !== undefined &&
                    activityList.map((activity: any, index: number) => {
                      return renderDisplayMessage(
                        isWebSocketEnabled ? activity.activityType : tabValue,
                        activity,
                        index
                      )
                    })}
                  {/* <tr>
                    <td className='text-end'>
                      <span
                        className='badge'
                        style={{backgroundColor: '#DFFFEA', color: '#17C653'}}
                      >
                        Visit
                      </span>
                    </td>
                    <td>
                      <span className='fw-semibold' style={{color: '#2F78EE'}}>
                        Bk&nbsp;
                      </span>
                      <span className='text-muted fw-semibold'>visited</span>
                      <span className='fw-semibold' style={{color: '#2F78EE'}}>
                        &nbsp;Stuti
                      </span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>1 day ago</td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr> */}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>

      <div className='card-footer'>
        {activityList.length !== 0 && (
          <CustomPagination
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPage={totalPage}
            cb={getPagination}
          />
        )}
      </div>
    </div>
  )
}

export {ActivityTable}
