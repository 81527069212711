/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {chartColors as colors} from '../../../../utils/Utils'
import {convertHourToTime} from '../../../../utils/DateUtils'

const BarCharts = (props) => {
  let {
    xAxisTitle,
    yAxisTitle,
    analytics,
    analyticsData,
    ChartsTitle,
    colorIndex,
    handleChange,
    isHorizontal,
  } = props
  const [chartOption, setChartOption] = useState({})
  const [chartSeries, setChartSeries] = useState(analyticsData)

  useEffect(() => {
    const options = {
      chart: {
        type: 'bar',
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            handleChange(config.dataPointIndex)
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: isHorizontal,
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: analytics,
        tickAmount: 10,
        title: {
          text: xAxisTitle,
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: true,
        opposite: false,
        reversed: false,
        logarithmic: false,
        logBase: 0,
        tickAmount: undefined,
        min: 0,
        max: undefined,
        stepSize: 2,
        forceNiceScale: true,
        floating: false,
        decimalsInFloat: 2,
        title: {
          text: yAxisTitle,
          style: {
            fontWeight: 700,
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value
          },
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
          colors: colors[colorIndex],
        },
      },
      markers: {
        size: 4,
        hover: {
          size: 7,
        },
        colors: colors[colorIndex],
      },
      tooltip: {
        enabled: true,
        inverseOrder: false,
        followCursor: true,
        shared: false,
        x: {
          show: true,
          offsetX: 0,
          formatter: function () {
            return ''
          },
        },
        custom: function ({series, seriesIndex, dataPointIndex, w}) {
          let date = w.globals.labels[dataPointIndex]
          let newDate = date
          if (newDate.length === 6) {
            newDate = newDate
          } else if (typeof date !== 'number') {
            newDate = date //DateFormatter(date)
          } else {
            newDate = convertHourToTime(date)
          }

          let tooltipContent = '<div class="apexcharts-tooltip-custom p-4">'
          tooltipContent += `<p>${newDate}</p>`
          for (let i = 0; i < series.length; i++) {
            const seriesName = w.globals.seriesNames[i]
            const color = w.globals.colors[i]
            var data = w.globals.initialSeries[i].data[dataPointIndex]
            tooltipContent += ` <div class="product">
             <div class="item">${seriesName}</div>
             <span class="legend-circle"  style="background-color:${color}"></span>
             ${data}</span>
         </div>`
          }
          tooltipContent += '</div>'
          return tooltipContent
        },
        y: {
          show: true,
          offsetY: 0,
        },
      },
      colors: colors[colorIndex],
    }

    setChartOption(options)

    setChartSeries(analyticsData)
  }, [analytics])

  return (
    <>
      <div>
        {analytics !== undefined && (
          <div className='card'>
            <h3 className='text-mute p-3'>{ChartsTitle}</h3>
            <Chart
              options={chartOption}
              series={chartSeries}
              type='bar'
              height='500'
              width='100%'
            />
          </div>
        )}
      </div>
    </>
  )
}

export default BarCharts
