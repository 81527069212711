import {useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {convertHourToTime} from '../../../../utils/DateUtils'
import {chartColors as colors} from '../../../../utils/Utils'

const LineCharts = (props) => {
  let {
    xAxisTitle,
    yAxisTitle,
    analytics,
    analyticsData,
    ChartsTitle,
    chartType,
    colorIndex,
    isRupeeSymbolShow,
    isShowCustomLegend,
  } = props
  const [chartOption, setChartOption] = useState({})

  const [chartSeries, setChartSeries] = useState(analyticsData)

  useEffect(() => {
    let options = {
      chart: {
        type: 'line',
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: 'smooth',
        dashArray: [0, 0, 0, 0, 0, 0, 5, 5, 5, 5, 5, 5],
      },
      xaxis: {
        categories: analytics?.hoursArray,
        tickAmount: 10,
        title: {
          text: xAxisTitle,
          style: {
            fontWeight: 700,
          },
        },
        labels: {
          show: true,
          formatter: (value) => {
            return value
          },
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: true,
        opposite: false,
        reversed: false,
        logarithmic: false,
        logBase: 0,
        tickAmount: undefined,
        min: 0,
        max: undefined,
        stepSize: 2,
        forceNiceScale: true,
        floating: false,
        decimalsInFloat: 2,
        title: {
          text: yAxisTitle,
          style: {
            fontWeight: 700,
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value
          },
        },
      },
      grid: {
        show: false,
        // row: {
        //   colors: ['#e5e5e5', 'transparent'],
        //   opacity: 0.5,
        // },
        // column: {
        //   colors: ['#f8f8f8', 'transparent'],
        // },
        // xaxis: {
        //   lines: {
        //     show: false,
        //   },
        // },
        // borderColor: '#111',
        // strokeDashArray: 7,
      },
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
          colors: colors[colorIndex],
        },
      },
      markers: {
        size: 4,
        hover: {
          size: 7,
        },
        colors: colors[colorIndex],
      },
      tooltip: {
        enabled: true,
        inverseOrder: false,
        followCursor: true,
        shared: false,
        x: {
          show: true,
          offsetX: 0,
          formatter: function () {
            return ''
          },
        },
        y: {
          show: true,
          offsetY: 0,
        },
        custom: function ({series, seriesIndex, dataPointIndex, w}) {
          let date = w.globals.categoryLabels[dataPointIndex]
          let newDate = date
          if (newDate.length === 6) {
            newDate = newDate
          } else if (typeof date !== 'number') {
            newDate = date //DateFormatter(date)
          } else {
            newDate = convertHourToTime(date)
          }

          let tooltipContent = '<div class="apexcharts-tooltip-custom p-4">'
          tooltipContent += `<p>${newDate}</p>`
          for (let i = 0; i < series.length; i++) {
            const seriesName = w.globals.seriesNames[i]
            const color = w.globals.colors[i]
            var data = w.globals.initialSeries[i].data[dataPointIndex]
            tooltipContent += ` <div class="product">
             <div class="item">${seriesName}</div>
             <span class="legend-circle"  style="background-color:${color}"></span>
             <span class="price">${isRupeeSymbolShow === true ? '₹' : ''}
             ${data}</span>
         </div>`
          }
          tooltipContent += '</div>'
          return tooltipContent
        },
      },

      colors: colors[colorIndex],
    }

    if (isShowCustomLegend) {
      options = {
        ...options,
        series: chartSeries.map((item, index) => ({
          ...item,
          type: index % 2 === 0 ? 'solid' : 'dashed',
        })),
        legend: {
          show: true,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            customHTML: function (legendContext = {}) {
              const {seriesIndex} = legendContext
              if (seriesIndex === undefined) return '<div></div>'

              const type = options.series[seriesIndex]?.type

              if (type === 'dashed') {
                return '<div style="border-bottom: 2px dashed; width: 12px;"></div>'
              } else {
                return '<div style="border-bottom: 2px solid; width: 12px;"></div>'
              }
            },
          },
          //   formatter: function (seriesName, opts) {
          //     const type = options.series[opts.seriesIndex]?.type
          //     if (type === 'solid') {
          //       return `${seriesName} (New)` // Custom label for solid series
          //     } else if (type === 'dashed') {
          //       return `${seriesName} (Recurring)` // Custom label for dashed series
          //     }
          //     return seriesName
          //   },
        },
      }
    }

    setChartOption(options)
    setChartSeries(analyticsData)
  }, [analytics])

  return (
    <>
      <div>
        {analytics !== undefined && (
          <div className='card'>
            <h3 className='text-mute p-3'>{ChartsTitle}</h3>
            <div></div>
            <Chart
              options={chartOption}
              series={chartSeries}
              type={chartType}
              height='400'
              width='100%'
            />
          </div>
        )}
      </div>
    </>
  )
}

export default LineCharts
