/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import {
  addAnswer,
  addQuestions,
  getUserQuestionAnswerForProfile,
  updateAnswers,
  updateQuestions,
} from '../../../../../../API/api-endpoint'
import {Form} from 'react-bootstrap'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import ToastUtils from '../../../../../../utils/ToastUtils'

const genders = [
  {name: 'All Genders', value: 0},
  {name: 'Male', value: 1},
  {name: 'Female', value: 2},
  {name: 'Lesbian', value: 3},
  {name: 'Gay', value: 4},
]

const Questions = () => {
  const [tableData, setTableData] = useState<any>([])
  const [getQuestionFlag, setGetQuestionFlag] = useState(1)
  const [currentAnswer, setCurrentAnswer] = useState<any>([])
  const [questionFormValue, setQuestionFormValue] = useState({
    question: '',
    order: 0,
    inputType: 'text',
    genderId: 0,
    answers: [],
  })

  useEffect(() => {
    getAllQuestionWithAnswer()
  }, [getQuestionFlag])

  const getAllQuestionWithAnswer = async () => {
    let result = await getUserQuestionAnswerForProfile()
    setTableData(result)
  }

  const handleAddRow = async () => {
    let oldTableData = [...tableData]
    oldTableData.splice(0, 0, questionFormValue)
    setTableData(oldTableData)

    //create questions api
    let result = await addQuestions(
      questionFormValue.question,
      questionFormValue.order,
      questionFormValue.inputType,
      questionFormValue.genderId
    )
    if (result.status === 200) {
      setQuestionFormValue({
        question: '',
        order: 1,
        inputType: 'text',
        genderId: 0,
        answers: [],
      })

      setGetQuestionFlag(getQuestionFlag + 1)
      ToastUtils({type: 'success', message: 'Questions Was Added'})
    } else {
      ToastUtils({type: 'error', message: 'Questions Was Not Added'})
    }
  }

  const handleInputChange = (event: any, index: any) => {
    if (event.target.name !== 'icon') {
      const updatedTableData = [...tableData]
      updatedTableData[index][event.target.name] = event.target.value
      setTableData(updatedTableData)
    } else if (event.target.name === 'genderId') {
      const updatedTableData = [...tableData]
      updatedTableData[index][event.target.name] = parseInt(event.target.value)
      setTableData(updatedTableData)
    } else {
      const updatedTableData = [...tableData]
      updatedTableData[index][event.target.name] = event.target.files[0]
      setTableData(updatedTableData)
    }
  }

  const handleCurrentInputChange = (event: any) => {
    if (event.target.name !== 'icon') {
      setQuestionFormValue({...questionFormValue, [event.target.name]: event.target.value})
    } else if (event.target.name === 'genderId') {
      setQuestionFormValue({
        ...questionFormValue,
        [event.target.name]: parseInt(event.target.value),
      })
    } else {
      setQuestionFormValue({...questionFormValue, [event.target.name]: event.target.files[0]})
    }
  }

  const handleUpdateQuestion = async (questionId) => {
    //update questions api
    let updatedData = tableData.filter((question) => question.questionId === questionId)
    let result = await updateQuestions(updatedData[0])
    if (result.status === 200) {
      setQuestionFormValue({
        question: '',
        order: 1,
        inputType: 'text',
        genderId: 0,
        answers: [],
      })
      setGetQuestionFlag(getQuestionFlag + 1)
      ToastUtils({type: 'success', message: 'Questions Was Updated'})
    } else {
      ToastUtils({type: 'error', message: 'Questions Was Not Updated'})
    }
  }

  const handleCurrentAnswerChange = (event: any, questionId: any, questionIndex: any) => {
    const currentAnswerData = [...currentAnswer]
    currentAnswerData[questionIndex] = event.target.value
    setCurrentAnswer(currentAnswerData)
  }

  const handleUpdateAnswer = async (questionIndex: any, questionId: any) => {
    const updatedTableData = [...tableData]
    let answerArray = updatedTableData[questionIndex].answers

    let answerText = currentAnswer[questionIndex]
    if (answerText !== undefined) {
      //here call a add a answer api
      let result = await addAnswer(answerText, questionId)
      if (result.status === 200) {
        let oldAnswerDetails = [...currentAnswer]
        oldAnswerDetails[questionIndex] = ''
        setCurrentAnswer(oldAnswerDetails)
        setGetQuestionFlag(getQuestionFlag + 1)
      }
    }

    if (answerArray.length !== 0) {
      let response = await updateAnswers(answerArray)
      if (response.status === 200) {
        setGetQuestionFlag(getQuestionFlag + 1)
        ToastUtils({type: 'success', message: 'Answer Was Updated'})
      } else {
        ToastUtils({type: 'error', message: 'Answer Was Not Updated'})
      }
    }
  }

  const handleExitingAnswer = (questionIndex: any, answerIndex: any, event: any) => {
    const updatedTableData = [...tableData]
    updatedTableData[questionIndex].answers[answerIndex].answer = event.target.value
    setTableData(updatedTableData)
  }

  return (
    <KTCardBody className='py-4 '>
      {/* <span className='fs-3 fw-bold '>Questions</span> */}
      <Table striped bordered hover className='mt-5'>
        <thead>
          <tr>
            <th>Question</th>
            <th>Type</th>
            <th>Gender</th>
            <th>Order</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>
                <InputGroup>
                  <FormControl
                    placeholder='Enter Questions'
                    name='question'
                    value={row.question}
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </InputGroup>
              </td>
              <td>
                <Form.Select
                  name='type'
                  value={row.inputType}
                  onChange={(event) => handleInputChange(event, index)}
                >
                  <option value='text'>Text</option>
                  <option value='select'>Select</option>
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  name='genderId'
                  defaultValue={row.genderId}
                  onChange={(event) => handleInputChange(event, index)}
                >
                  {genders.map((gender, optionIndex) => (
                    <option key={optionIndex} value={gender.value}>
                      {gender.name}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    placeholder='Order'
                    name='order'
                    value={row.order}
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </InputGroup>
              </td>
              <td>
                <Button
                  size='sm'
                  variant='primary'
                  onClick={() => handleUpdateQuestion(row.questionId)}
                >
                  <i className='fa-solid fa-circle-check'></i>
                </Button>{' '}
                <Button
                  size='sm'
                  variant='danger'
                  //onClick={() => handleRemoveQuestion(row.questionId)}
                >
                  <i className='fa-solid fa-trash'></i>
                </Button>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <InputGroup>
                <FormControl
                  placeholder='Enter Questions'
                  name='question'
                  value={questionFormValue.question}
                  onChange={(event) => handleCurrentInputChange(event)}
                />
              </InputGroup>
            </td>
            <td>
              <Form.Select
                name='inputType'
                value={questionFormValue.inputType}
                onChange={(event) => handleCurrentInputChange(event)}
              >
                <option value='text'>Text</option>
                <option value='select'>Select</option>
              </Form.Select>
            </td>
            <td>
              <Form.Select
                name='genderId'
                value={questionFormValue.genderId}
                onChange={(event) => handleCurrentInputChange(event)}
              >
                {genders.map((gender, optionIndex) => (
                  <option key={optionIndex} value={gender.value}>
                    {gender.name}
                  </option>
                ))}
              </Form.Select>
            </td>
            <td>
              <Button size='sm' variant='primary' onClick={handleAddRow}>
                <i className='fa-solid fa-plus'></i>
              </Button>{' '}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className='card p-4 mb-4 fw-bold fs-6'>Possible answers for question type SELECT</div>
      <div>
        <div className='row g-5 g-xl-8'>
          {tableData !== undefined &&
            tableData.map((question: any, questionIndex: number) => {
              return (
                <div className='col-xl-4' key={questionIndex}>
                  <div className='card p-3' style={{height: '300px'}}>
                    <div className='d-flex justify-content-between'>
                      <h3 className='mt-3 fw-bold text-gray-800 fs-3'>{question.question}</h3>
                      <button
                        className='btn btn-light text-black'
                        onClick={() => handleUpdateAnswer(questionIndex, question.questionId)}
                      >
                        Update
                      </button>
                    </div>
                    <hr></hr>
                    <div className='h-200px overflow-scroll'>
                      {question.answers.map((answer: any, answerIndex: number) => {
                        return (
                          <FormControl
                            key={answerIndex}
                            name='answer'
                            value={answer.answer}
                            onChange={(event) =>
                              handleExitingAnswer(questionIndex, answerIndex, event)
                            }
                            className='border border-0'
                          />
                        )
                      })}

                      <FormControl
                        placeholder='Add Answer'
                        name='answer'
                        value={currentAnswer[questionIndex]}
                        onChange={(event) =>
                          handleCurrentAnswerChange(event, question.questionId, questionIndex)
                        }
                        className='border border-0'
                      />
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </KTCardBody>
  )
}

export default Questions
