import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const GeneralPlugin = () => {
  const [authConfigID, setAuthConfigId] = useState(0)
  const [generalConfigID, setGeneralConfigId] = useState(0)

  const [authConfig, setAuthConfig] = useState<any>({
    isEnabledForceEmailVerifications: true,
    isEnabledForceMobileVerifications: true,
    isMaintenance: false,
    isEnabledGoogleLogin: false,
    isEnabledAppleLogin: false,
    tokenVersion: '',
  })

  const [generalConfig, setGeneralConfig] = useState<any>({
    isWebSocketEnabled: false,
    isInAppPurchaseEnabled: true,
    isManualPurchaseEnabled: true,
    isProfileImageRequired: true,
  })

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleAuthChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    if (name === 'tokenVersion') {
      setAuthConfig({...authConfig, [name]: event.target.value})
    } else {
      setAuthConfig({...authConfig, [name]: value})
      updateAuthConfiguration({...authConfig, [name]: value})
    }
  }

  const handleGeneralChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    setGeneralConfig({...generalConfig, [name]: value})
    updateGeneralConfiguration({...generalConfig, [name]: value})
  }

  const onBlurAuthUpdate = () => {
    updateAuthConfiguration(authConfig)
  }

  const getConfiguration = async () => {
    let result1 = await getConfigurationByName('auth')
    let result2 = await getConfigurationByName('general_setting')

    if (result1.success) {
      let parsedData = JSON.parse(result1.data.values)
      setAuthConfigId(result1.data.id)
      setAuthConfig({
        isEnabledForceEmailVerifications: parsedData?.isEnabledForceEmailVerifications,
        isEnabledForceMobileVerifications: parsedData?.isEnabledForceMobileVerifications,
        isMaintenance: parsedData?.isMaintenance,
        isEnabledGoogleLogin: parsedData?.isEnabledGoogleLogin,
        isEnabledAppleLogin: parsedData?.isEnabledAppleLogin,
        tokenVersion: parsedData?.tokenVersion,
      })
    }
    if (result2.success) {
      let parsedData = JSON.parse(result2.data.values)
      setGeneralConfigId(result2.data.id)
      setGeneralConfig({
        isWebSocketEnabled: parsedData?.isWebSocketEnabled,
        isInAppPurchaseEnabled: parsedData?.isInAppPurchaseEnabled,
        isManualPurchaseEnabled: parsedData?.isManualPurchaseEnabled,
        isProfileImageRequired: parsedData?.isProfileImageRequired,
      })
    }
  }

  const updateAuthConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(authConfigID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  const updateGeneralConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(generalConfigID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>General settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Force users to verify their email</strong>
            </p>
            <p className='text-muted'>
              This feature requires a working configuration of the SMTP plugin, before enable this
              make sure your SMTP service is working correctly
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledForceEmailVerifications'
                checked={authConfig.isEnabledForceEmailVerifications}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Force users to verify their Mobile No</strong>
            </p>
            <p className='text-muted'>In User Onboarding Enable Or Disable Verify a Mobile No</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledForceMobileVerifications'
                checked={authConfig.isEnabledForceMobileVerifications}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Force Set to Maintenance</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isMaintenance'
                checked={authConfig.isMaintenance}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Google Login</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Google Login Button</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledGoogleLogin'
                checked={authConfig.isEnabledGoogleLogin}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Apple Login</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Apple Login Button</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledAppleLogin'
                checked={authConfig.isEnabledAppleLogin}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>app Purchase Enabled</strong>
            </p>
            <p className='text-muted'>Enable Or Disable In App Purchase</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isInAppPurchaseEnabled'
                checked={generalConfig.isInAppPurchaseEnabled}
                onChange={(event) => handleGeneralChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Manual Payment Enabled</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Manual Payment Gateway Like PhonePay</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isManualPurchaseEnabled'
                checked={generalConfig.isManualPurchaseEnabled}
                onChange={(event) => handleGeneralChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Is Profile Photo Is Required</strong>
            </p>
            <p className='text-muted'>In Onboarding Enable Or Disable Profile Photo Is Required</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isProfileImageRequired'
                checked={generalConfig.isProfileImageRequired}
                onChange={(event) => handleGeneralChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Access Token Version Code</strong>
            </p>
            <p className='text-muted'>
              If You Change This Version Code All login User Will Be ForceFully Logout
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                type='number'
                className='form-control'
                name='tokenVersion'
                value={authConfig.tokenVersion}
                onChange={(event) => handleAuthChange(event)}
                onBlur={onBlurAuthUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralPlugin
