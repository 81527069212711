import moment from 'moment'

//  31-01-2024 added a toLocaleString in this all functions

export const DateTimeFormatter = (date) => {
  let localDate = moment(date).toLocaleString()
  return moment(localDate).format('DD/MM/YYYY')
}

export const DateFormatter = (date: any) => {
  let localDate = moment('date').toLocaleString()
  return moment(localDate).format('DD-MM-YYYY')
}

export const DateWithTimeFormatter = (date) => {
  let localDate = moment(date).toLocaleString()
  return moment(localDate).format('DD/MM/YYYY hh:mm a')
}

export const TimeFormatter = (date) => {
  let localDate = moment(date).toLocaleString()
  return moment(localDate).format('hh:mm a')
}

export const convertUserTableDate = (dateString: any) => moment(dateString).format('DD MMM YY')
export const convertUserTableTime = (dateString: any) => moment(dateString).format('hh:mm a')

export const calculateTimeDifference = (givenTime: any) => {
  const currentTime = new Date()
  const pastTime = new Date(givenTime)

  // Check if the givenTime is a valid date
  if (isNaN(pastTime.getTime())) {
    return 'Invalid date'
  }

  const timeDifferenceInMilliseconds = currentTime.getTime() - pastTime.getTime()
  const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000
  const timeDifferenceInMinutes = timeDifferenceInSeconds / 60
  const timeDifferenceInHours = timeDifferenceInMinutes / 60
  const timeDifferenceInDays = timeDifferenceInHours / 24
  const timeDifferenceInMonths = timeDifferenceInDays / 30
  const timeDifferenceInYears = timeDifferenceInDays / 365.25 // Consider leap years

  if (timeDifferenceInYears >= 1) {
    return `${Math.floor(timeDifferenceInYears)} year${
      Math.floor(timeDifferenceInYears) > 1 ? 's' : ''
    } ago`
  } else if (timeDifferenceInMonths >= 1) {
    return `${Math.floor(timeDifferenceInMonths)} month${
      Math.floor(timeDifferenceInMonths) > 1 ? 's' : ''
    } ago`
  } else if (timeDifferenceInDays >= 1) {
    return `${Math.floor(timeDifferenceInDays)} day${
      Math.floor(timeDifferenceInDays) > 1 ? 's' : ''
    } ago`
  } else if (timeDifferenceInHours >= 1) {
    return `${Math.floor(timeDifferenceInHours)} hour${
      Math.floor(timeDifferenceInHours) > 1 ? 's' : ''
    } ago`
  } else if (timeDifferenceInMinutes >= 1) {
    return `${Math.floor(timeDifferenceInMinutes)} minute${
      Math.floor(timeDifferenceInMinutes) > 1 ? 's' : ''
    } ago`
  } else {
    return `${Math.abs(Math.floor(timeDifferenceInSeconds))} second${
      Math.floor(timeDifferenceInSeconds) > 1 ? 's' : ''
    } ago`
  }
}

export const calculateTimeDifferenceForChatMessage = (givenTime: any) => {
  const currentTime = new Date()
  const pastTime = new Date(givenTime)
  const timeDifferenceInMilliseconds = currentTime.getTime() - pastTime.getTime()
  const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000
  const timeDifferenceInMinutes = timeDifferenceInSeconds / 60
  const timeDifferenceInHours = timeDifferenceInMinutes / 60

  let localDate = moment(pastTime).toLocaleString()
  if (timeDifferenceInHours >= 48) {
    return moment(localDate).format('DD/MM/YYYY')
  } else if (timeDifferenceInHours <= 48 && timeDifferenceInHours >= 24) {
    return `Yesterday`
  } else {
    return moment(localDate).format('hh:mm a')
  }
}

export const calculateTimeDifferenceForActivity = (givenTime: any) => {
  const currentTime = new Date()
  const pastTime = new Date(givenTime)
  const timeDifferenceInMilliseconds = currentTime.getTime() - pastTime.getTime()
  const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000
  const timeDifferenceInMinutes = timeDifferenceInSeconds / 60
  const timeDifferenceInHours = timeDifferenceInMinutes / 60

  let localDate = moment(pastTime).toLocaleString()
  if (timeDifferenceInHours >= 24) {
    return moment(localDate).format('hh:mm a DD-MMM-YYYY')
  } else {
    return moment(localDate).format('hh:mm a')
  }
}

export const oldCalculateDateTimeDifference = (pastDateString: any) => {
  const currentDate = new Date()
  const pastDate = new Date(pastDateString)
  const timeDifference = Math.abs(currentDate.getTime() - pastDate.getTime())
  const minutes = Math.floor(timeDifference / (60 * 1000))
  const hours = Math.floor(timeDifference / (60 * 60 * 1000))
  const days = Math.floor(hours / 24)
  const years = Math.floor(days / 365)

  let finalTime: string

  if (years > 0) {
    finalTime = years + ' Y'
  } else if (days > 0) {
    finalTime = days + ' D'
  } else if (hours > 0) {
    finalTime = hours + ' H'
  } else {
    finalTime = minutes + ' M'
  }

  return finalTime
}

export const getDaysBetweenTwoDate = (date1: any, date2: any) => {
  // Define two date objects
  const firstDate: any = new Date(date1)
  const secondDate: any = new Date(date2)

  // Calculate the difference in milliseconds
  const differenceMs = secondDate - firstDate

  // Convert milliseconds to days
  const differenceDays = differenceMs / (1000 * 60 * 60 * 24)

  return differenceDays
}

export const convertHourToTime = (hour: any) => {
  if (hour < 0 || hour > 24) {
    return 'Invalid hour'
  }

  const hourString = hour < 10 ? '0' + hour : hour.toString()

  const date = new Date(`2000-01-01T${hourString}:00:00`)

  const timeString = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})

  return timeString
}

export const countDaysForPremium = (premiumDate: any, premiumDays: any) => {
  let days = getDaysBetweenTwoDate(premiumDate, new Date())
  days = Math.round(days)
  return premiumDays - days
}

export const getCurrentWeek = () => {
  const currentDate = new Date()
  const dayOfWeek = currentDate.getDay() // 0 (Sunday) to 6 (Saturday)

  const startOfWeek = new Date(currentDate)
  startOfWeek.setDate(currentDate.getDate() - dayOfWeek)

  const endOfWeek = new Date(currentDate)
  endOfWeek.setDate(currentDate.getDate() + (6 - dayOfWeek))

  return {
    startOfWeek: startOfWeek.toISOString().split('T')[0], // format as YYYY-MM-DD
    endOfWeek: endOfWeek.toISOString().split('T')[0], // format as YYYY-MM-DD
  }
}

export const getCurrentMonth = () => {
  const currentDate = new Date()
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2)
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)

  return {
    startOfMonth: startOfMonth.toISOString().split('T')[0],
    endOfMonth: endOfMonth.toISOString().split('T')[0],
  }
}

export const getEighteenYearsOldDate = () => {
  const currentDate = new Date()
  const maxDate = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  )
  let maxDateString = maxDate.toISOString().split('T')[0]
  return maxDateString
}

export const getAge = (DOB: any) => {
  return moment().diff(DOB, 'years')
}

export const calculateTimeDifferenceBetWeenTwoTimes = (startTime: any, endTime: any) => {
  let start = new Date(startTime)

  // If endTime is true, use the current time; otherwise, parse endTime as a date
  let end = endTime === true ? new Date() : new Date(endTime)

  // Calculate the difference in milliseconds
  let differenceInMilliseconds = end.getTime() - start.getTime()

  // Convert milliseconds to total seconds
  const totalSeconds = Math.floor(differenceInMilliseconds / 1000)

  const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0')
  const seconds = String(totalSeconds % 60).padStart(2, '0')

  // Format the duration as HH:MM:SS
  const formattedDuration = `${hours}:${minutes}:${seconds}`

  return formattedDuration
}
