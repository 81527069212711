import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'
import {escape} from 'querystring'

const StoryPlugin = () => {
  const [configID, setConfigId] = useState(0)
  const [storyConfig, setStoryConfig] = useState<any>({
    storyPrice: 0,
    isTransferCreditEnabled: false,
    transferCreditPercentage: 50,
    search: '',
  })

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleChange = (event: any) => {
    let name = event.target.name

    if (name === 'search') {
      setStoryConfig({...storyConfig, [name]: event.target.value})
      updateConfiguration({...storyConfig, [name]: event.target.value})
    } else if (name !== 'isTransferCreditEnabled') {
      setStoryConfig({...storyConfig, [name]: event.target.value})
    } else {
      setStoryConfig({...storyConfig, [name]: event.target.checked})
      updateConfiguration({...storyConfig, [name]: event.target.checked})
    }
  }

  const getConfiguration = async () => {
    let result = await getConfigurationByName('story')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigId(result.data.id)
      setStoryConfig({
        storyPrice: parsedData.storyPrice,
        isTransferCreditEnabled: parsedData.isTransferCreditEnabled,
        transferCreditPercentage: parsedData.transferCreditPercentage,
        search: parsedData.search,
      })
    }
  }

  const onBlurUpdate = () => {
    updateConfiguration(storyConfig)
  }

  const updateConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>Story settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Story Price Limit</strong>
            </p>
            <p className='text-muted'>Set a maximum credit limit for uploading stories.</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='number'
                className='form-control'
                name='storyPrice'
                value={storyConfig.storyPrice}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer Credit Are Enabled</strong>
            </p>
            <p className='text-muted'>Enable or Disable Transfer Credit</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isTransferCreditEnabled'
                checked={storyConfig.isTransferCreditEnabled}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer Credit Percentage</strong>
            </p>
            <p className='text-muted'>Enter Percentage Here</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='number'
                className='form-control'
                name='transferCreditPercentage'
                value={storyConfig.transferCreditPercentage}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Filter proximity range</strong>
            </p>
            <p className='text-muted'>Select which proximity range use to search story</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='gender'
                data-hide-search='true'
                //defaultValue={storyConfig.search}
                name='search'
                value={storyConfig.search}
                onChange={(event) => handleChange(event)}
              >
                <option value='worldwide'>WorldWide</option>
                <option value='country'>Country</option>
                <option value='state'>State</option>
                <option value='city'>City</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryPlugin
