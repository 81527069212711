import axios from 'axios'
import moment from 'moment'
import {request} from '../middleware/auth-intercepter'
import {API_TYPE} from '../utils/Utils'
import CustomLogger from '../utils/CustomLogger'

let APIURL = process.env.REACT_APP_SERVER_URL + '/api/v1'

export const getAllUser = async (data) => {
  try {
    const apiUrl = `/web/users/allUser?${data}`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response.data
  } catch (error) {
    CustomLogger.error('Error in Get All User', error.message)
    return error.message
  }
}

export const getAllUserForChatModerator = async (query) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const loginUser = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')
    let isAdmin = true

    if (loginUser.roleName === 'Admin Chat Moderator' || loginUser.roleName === 'Chat Moderator') {
      isAdmin = false
    }

    let apiUrl = `${APIURL}/users/allUser?moderatorId=1&${query}&isAdmin=${isAdmin}` //for admin

    if (query.includes('moderatorId=')) {
      apiUrl = `${APIURL}/users/allUser?${query}&isAdmin=${isAdmin}`
    }

    if (loginUser.roleName === 'Chat Moderator') {
      apiUrl = `${APIURL}/users/allUser?moderatorId=${loginUser.adminUserId}&${query}&isAdmin=${isAdmin}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUser = async (userID) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/${userID}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data.data
  } catch (error) {
    return error.message
  }
}

export const deleteUserAccount = async (userIDs) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      userIds: userIDs,
    }

    const apiUrl = `${APIURL}/users/delete`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    return error.message
  }
}

export const activeInactiveUser = async (userIDs, status) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    // /anonymous/users/21/updatestatus?status=true

    let formdata = new FormData()
    formdata = {
      userIds: userIDs,
      status: status,
    }

    const apiUrl = `${APIURL}/anonymous/users/updatestatus`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const registerNewUser = async (profilePic, newUserData) => {
  let accessToken = localStorage.getItem('accessToken')
  let formdata = new FormData()
  formdata = {
    profile: '',
    mobileNo: '',
    countryCode: 91,
    fullName: '',
    userName: '',
    email: '',
    genderId: '',
    birthDate: '',
    registerFrom: 'app',
  }

  const apiUrl = `${APIURL}/users/register`

  let response = await axios.post(apiUrl, formdata, {
    headers: {
      'Content-Type': `application/json`,
      'x-access-token': accessToken,
    },
  })

  return response.data
}

export const createGender = async (genderName) => {
  let accessToken = localStorage.getItem('accessToken')
  let formdata = new FormData()
  formdata = {
    name: genderName,
  }

  const apiUrl = `${APIURL}/masters/genders`

  let response = await axios.post(apiUrl, formdata, {
    headers: {
      'Content-Type': `application/json`,
      'x-access-token': accessToken,
    },
  })

  return response.data
}

export const getAllGender = async (genderName) => {
  let accessToken = localStorage.getItem('accessToken')

  const apiUrl = `${APIURL}/masters/genders`

  let response = await axios.get(apiUrl, {
    headers: {
      'Content-Type': `application/json`,
      'x-access-token': accessToken,
    },
  })

  return response.data
}

export const loginWithEmailAndPassword = async (email, password) => {
  const apiUrl = `/auth/admin/login`

  let data = {
    email: email,
    password: password,
  }

  let response = await request(apiUrl, API_TYPE.POST, data, false)

  if (response.success) {
    localStorage.setItem('accessToken', response.data.token)
    sessionStorage.setItem('accessToken', response.data.token)
    localStorage.setItem('loginUserId', response.data.adminUserId)
  }

  return response.data
}

export const getCitiesBYSearch = async (searchText) => {
  try {
    const apiUrl = `/masters/cities?search=${searchText}&citySearch=${0}`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getStateBYSearch = async (searchText) => {
  try {
    const apiUrl = `/masters/states?search=${searchText}`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCountriesList = async () => {
  try {
    const apiUrl = `/masters/countries`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getStateList = async (countryID) => {
  try {
    const apiUrl = `/masters/countries/${countryID}/states`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCityList = async (stateID) => {
  try {
    const apiUrl = `/masters/countries/states/${stateID}/cities`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserProfileDetailsUsingUserID = async (userID) => {
  try {
    const apiUrl = `/web/users/profile/${userID}`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserActivityWithPagination = async (page, pageSize, type, userID, filter) => {
  try {
    let data = {
      page: page,
      pageSize: pageSize,
      userId: userID,
      type: type,
    }
    if (filter.type === 'lastHour') {
      data['lastHour'] = filter.type
    }

    if (filter.type === 'lastDay') {
      data['lastDay'] = filter.type
    }

    if (filter.type === 'lastWeek') {
      data['lastWeek'] = filter.type
    }

    if (filter.type === 'custom') {
      data['startDate'] = filter.startDate
      data['endDate'] = filter.endDate
    }

    const apiUrl = `/web/users/activities/`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getDashboardActivityWithPagination = async (page, pageSize, type) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/activities/dashboard?page=${page}&pageSize=${pageSize}&type=${type}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllInterest = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/interests`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createInterest = async (name) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      name: name,
    }

    const apiUrl = `${APIURL}/masters/interests`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateInterest = async (interestId, name) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      name: name,
    }

    const apiUrl = `${APIURL}/masters/interests/${interestId}`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const removeInterest = async (interestId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/interests/${interestId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserInterest = async (userID) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/${userID}/interest`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addUserInterest = async (userID, interestId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      interestId: interestId,
    }

    const apiUrl = `${APIURL}/users/${userID}/interest`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const removeUserInterest = async (userID, interestId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      interestId: interestId,
    }

    const apiUrl = `${APIURL}/users/${userID}/interest`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
      data: formdata,
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addQuestions = async (question, order, inputType, genderId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = new FormData()
    if (genderId === 0) {
      formdata = {
        question: question,
        order: order,
        inputType: inputType,
      }
    } else {
      formdata = {
        question: question,
        order: order,
        inputType: inputType,
        genderId: parseInt(genderId),
      }
    }

    const apiUrl = `${APIURL}/masters/questions`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `multipart/form-data`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateQuestions = async (updatedData, file) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      question: updatedData.question,
      order: updatedData.order,
      inputType: updatedData.inputType,
      genderId: updatedData.genderId,
    }

    const apiUrl = `${APIURL}/masters/questions/${updatedData.questionId}`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `multipart/form-data`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addAnswer = async (answer, questionId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      answer: answer,
      questionId: questionId,
    }

    const apiUrl = `${APIURL}/masters/answers`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateAnswers = async (answers) => {
  //
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      answers: answers,
    }

    const apiUrl = `${APIURL}/masters/answers`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserQuestionAnswerForProfile = async () => {
  try {
    const apiUrl = `/masters/questions/answer`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const createUserQuestionAnswerForProfile = async (userID, questionID, answerID) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = new FormData()
    formdata = {
      questionId: questionID,
      answerId: answerID,
    }

    const apiUrl = `${APIURL}/users/${userID}/questions`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateUserQuestionAnswerForProfile = async (userID, questionID, answerID) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = new FormData()
    formdata = {
      questionId: questionID,
      answerId: answerID,
    }

    const apiUrl = `${APIURL}/users/${userID}/questions`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteUserQuestionAnswerForProfile = async (
  userID,
  questionID,
  answerID,
  userQuestionId
) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = new FormData()
    formdata = {
      questionId: questionID,
      answerId: answerID,
    }

    const apiUrl = `${APIURL}/users/${userID}/questions/${userQuestionId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
      data: formdata,
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserMedia = async (userID, page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/${userID}/profile/media?page=${page}&pageSize=${pageSize}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updatemultiMediaActionForMedia = async (userID, mediaIDArray, action) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let body = {
      mediaIds: mediaIDArray,
      action: action,
    }

    const apiUrl = `${APIURL}/users/${userID}/media/admin`

    let response = await axios.put(apiUrl, body, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateMediaActionForUserMedia = async (userID, mediaID) => {
  try {
    let data = {
      isProfileImage: true,
      id: mediaID,
      userId: userID,
    }

    const apiUrl = `/web/users/media/update`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const removeMediaActionForUserMedia = async (userID, mediaID) => {
  try {
    const apiUrl = `/web/users/media/delete`

    let response = await request(apiUrl, API_TYPE.POST, {userId: userID, id: mediaID}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createMediaActionForUserMedia = async (fileData, userID, userType) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formData = new FormData()

    formData.append('userId', userID)

    fileData.forEach((file, i) => {
      const fileName = `${userType}_${userID}_${i}.webp`
      formData.append('profileMedia', file, fileName)
    })

    let baseUrl = process.env.REACT_APP_SERVER_URL + '/api/v1'

    const apiUrl = `${baseUrl}/web/users/upload/profiles`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
        isencryption: false,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createMediaActionForUserMediaForAnonymousUser = async (fileData, userID, userType) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formData = new FormData()
    // for (var i = 0; i < fileData.length; i++) {
    //   let fileName = userType + "_" + userID + ".webp"
    //   formData.append('anonymousProfileMedia', fileData[i], fileName ) // fileData[i].name .split(".")[0] + ".webp"
    //   formData.append(`mediaTypes[${i}][mediaType]`, 'photo')
    // }

    fileData.forEach((file, i) => {
      const fileName = `${userType}_${userID}_${i}.webp`
      formData.append('anonymousProfileMedia', file, fileName)
      formData.append(`mediaTypes[${i}][mediaType]`, 'photo')
    })

    console.log('formData', formData)

    const apiUrl = `${APIURL}/users/${userID}/upload/profiles/web`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const createMediaActionForUserMediaForAnonymousUser1 = async (
  fileData,
  userID,
  userType
) => {
  try {
    const accessToken = localStorage.getItem('accessToken')

    const formData = new FormData()

    fileData.forEach((file, i) => {
      const fileName = `${userType}_${userID}.webp`
      formData.append('anonymousProfileMedia', file, fileName)
      formData.append(`mediaTypes[${i}][mediaType]`, 'photo')
    })

    console.log('formData', formData)

    const apiUrl = `${APIURL}/users/${userID}/upload/profiles/web`

    const response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.error(error.message)
    return error.message
  }
}

export const setMediaAsAStoryForUserMedia = async (userID, mediaId) => {
  try {
    const apiUrl = `/web/users/media/upload/story`

    let response = await request(apiUrl, API_TYPE.POST, {userId: userID, id: mediaId}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserCreditsHistoryWithPagination = async (
  userID,
  page,
  pageSize,
  creditType,
  filterValue,
  historyType
) => {
  try {
    let data = {userId: userID, page: page, pageSize: pageSize, type: creditType}
    if (filterValue.creditTitle) {
      data['creditHistoryTitle'] = filterValue.creditTitle
    }

    if (filterValue.startDate && filterValue.endDate) {
      data['startDate'] = filterValue.startDate
      data['endDate'] = filterValue.endDate
    }

    if (historyType) {
      data['historyType'] = historyType
    }

    const apiUrl = `/web/users/credits/get`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserPurchaseHistoryWithPagination = async (userID, page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/web/users/${userID}/purchase/history?page=${page}&pageSize=${pageSize}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdateUserDetailsByUID = async (UID, newUserData) => {
  try {
    let formdata = new FormData()
    formdata = {
      mobileNo: newUserData.mobileNo,
      countryCode: newUserData.countryCode,
      fullName: newUserData.fullName,
      userName: newUserData.userName,
      email: newUserData.email,
      genderId: parseInt(newUserData.gender),
      city: newUserData.city,
      state: newUserData.state,
      country: newUserData.country,
      lat: newUserData.lat,
      lng: newUserData.lng,
      birthDate: newUserData.birthDate,
      bio: newUserData.bio,
      questions: JSON.stringify(newUserData?.questions),
      interests: JSON.stringify(newUserData?.interests),
    }

    const apiUrl = `/web/users/update/${UID}`

    let response = await request(apiUrl, API_TYPE.PUT, formdata, true)

    return response
  } catch (error) {
    return error.response.data
  }
}

export const UpdateVerifyStatusByUID = async (UID, flag) => {
  try {
    let formdata = new FormData()
    formdata = {
      isVerify: flag,
    }

    const apiUrl = `/web/users/update/${UID}`

    let response = await request(apiUrl, API_TYPE.PUT, formdata, true)

    return response
  } catch (error) {
    return error.response.data
  }
}

export const UpdateSpotlightStatusByUID = async (UID, duration, type) => {
  try {
    let formData = new FormData()
    formData = {
      duration: duration,
      type: type,
      userId: UID,
    }

    const apiUrl = `/web/users/spotlight/update`

    let response = await request(apiUrl, API_TYPE.PUT, formData, true)

    return response
  } catch (error) {
    return error.response.data
  }
}

export const AddOrUpdateCreditByUserId = async (userId, type, credit) => {
  try {
    let formData = new FormData()
    formData = {
      type: type,
      credit: credit,
      userId: userId,
    }

    const apiUrl = `/web/users/send/credit`

    let response = await request(apiUrl, API_TYPE.POST, formData, true)

    return response
  } catch (error) {
    return error.response.data
  }
}

export const AddOrUpdateDiamondsByUserId = async (userId, type, diamond) => {
  try {
    let formData = new FormData()
    formData = {
      type: type,
      diamond: diamond,
      userId: userId,
    }

    const apiUrl = `/web/users/send/diamond`

    let response = await request(apiUrl, API_TYPE.POST, formData, true)

    return response
  } catch (error) {
    return error.response.data
  }
}

export const AddOrUpdatePremiumByUID = async (
  UID,
  type,
  days,
  premiumPackageAmountId,
  paymentVia
) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formData = new FormData()
    formData = {
      type: type,
      days: days,
      premiumPackageAmountId: premiumPackageAmountId,
      paymentVia: paymentVia,
    }

    const apiUrl = `${APIURL}/web/users/${UID}/send/premium`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    return error.response.data
  }
}

// /:userId/remove/:pkgId/premium

export const removePremium = async (userId, premiumPackageAmountId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/web/users/${userId}/remove/${premiumPackageAmountId}/premium`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getSpotlightUsers = async (Page, pageSize, userType) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/web/users/spotlight/web?page=${Page}&pageSize=${pageSize}&registerFrom=${userType}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const removeSpotlightUser = async (userID) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/${userID}/remove/spotlight`

    let response = await axios.put(
      apiUrl,
      {},
      {
        headers: {
          'Content-Type': `application/json`,
          'x-access-token': accessToken,
        },
      }
    )

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

/*For Chats */
export const getMessagesByUserID = async (userID, chatroomID, page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/communications/chat/users/${userID}/chatroom/${chatroomID}/messages?page=${page}&pageSize=${pageSize}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getChatMemberByUserID = async (
  userID,
  page,
  pageSize,
  registerFrom,
  search,
  userType
) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/communications/chat/users/${userID}?page=${page}&pageSize=${pageSize}&registerFrom=${registerFrom}&search=${search}&userType=${userType}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllGifts = async (page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/gifts/web`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllGiftsCategory = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/gifts-category`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const sendCreditInChat = async (senderID, receiverId, credit) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formData = new FormData()
    formData = {
      receiverId: receiverId,
      credit: credit,
    }

    const apiUrl = `${APIURL}/users/${senderID}/send/credit`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const pinOrLikeChatMember = async (userID, roomID, chatID, action, currentActionValue) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata
    if (action === 'pin') {
      formdata = {
        pin: currentActionValue,
      }
    } else if (action === 'like') {
      formdata = {
        like: currentActionValue,
      }
    }

    const apiUrl = `${APIURL}/communications/chat/users/${userID}/chatroom/${roomID}/chatMember/${chatID}`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

/*for story */

export const getAllUserStory = async (userId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/1/stories/view/${userId}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const CreateUserStory = async (fileData, userId, userType) => {
  try {
    let mediaType = fileData.type.split('/')[0]
    let formData = new FormData()

    // userType = "n"  = normal users
    // userType = "a"  = anonymous users

    let fileName = userType + '_' + userId + '.' + fileData.name.split('.').pop()
    formData.append('media', fileData, fileName)
    formData.append(`mediaType`, mediaType == 'image' ? 'photo' : mediaType)

    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/${userId}/stories`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteUserStory = async (userId, mediaId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/${userId}/stories/${mediaId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdateUserStory = async (storyCredit, userId, storyId) => {
  try {
    storyCredit = parseInt(storyCredit)
    let formData = new FormData()
    if (storyCredit !== 0) {
      formData = {
        isPrivate: true,
        storyCredit: storyCredit,
      }
    } else {
      formData = {
        isPrivate: false,
        storyCredit: storyCredit,
      }
    }

    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/${userId}/stories/${storyId}`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const ReUploadUserStory = async (userId, storyId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/${userId}/stories/${storyId}/re-upload`

    let response = await axios.post(
      apiUrl,
      {},
      {
        headers: {
          'Content-Type': `application/json`,
          'x-access-token': accessToken,
        },
      }
    )

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// for send message using pusher

export const sendMessageUsingApi = async (message, userId, receiverId, chatRoomID, chatID) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formData = new FormData()
    formData = {
      message: message,
      senderId: userId,
      receiverId: receiverId,
      type: 'text',
      chatRoomId: chatRoomID,
      chatId: chatID,
    }
    const apiUrl = `${APIURL}/communications/chat/users/${receiverId}/chatroom/${chatRoomID}/messages`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getAllMedia = async (page, pageSize, isPrivate, userID, userName) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    //  /users/profile/media?page=1&pageSize=10&isPrivate=false&userId=35
    const apiUrl = `${APIURL}/users/profile/media?page=${page}&pageSize=${pageSize}&isPrivate=${isPrivate}&userId=${userID}&userName=${userName}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteSelectedMedia = async (MediaId) => {
  try {
    let formData = {
      mediaIds: MediaId,
      action: 'delete',
    }

    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/media/admin`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllStories = async (page, pageSize, isPrivate, userID, userName) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/users/all/stories?page=${page}&pageSize=${pageSize}&isPrivate=${isPrivate}&userId=${userID}&userName=${userName}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteSelectedStory = async (MediaId) => {
  try {
    let formData = {
      storyIds: MediaId,
    }

    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/delete/all/stories`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const ReUploadSelectedStory = async (MediaId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formData = {
      storyIds: MediaId,
    }

    const apiUrl = `${APIURL}/users/re-upload/all/stories`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// gifts plugin

export const getAllGiftCategory = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    //  /users/profile/media?page=1&pageSize=10&isPrivate=false&userId=35
    const apiUrl = `${APIURL}/masters/gifts-category`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const CreateGiftsCategory = async (name) => {
  try {
    let formData = new FormData()
    formData = {
      name: name,
    }

    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/gifts-category`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateGiftsCategory = async (name, giftCategoryId) => {
  try {
    let formData = new FormData()
    formData = {
      name: name,
    }

    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/gifts-category/${giftCategoryId}`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteGiftsCategory = async (giftCategoryId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/gifts-category/${giftCategoryId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllGift = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    //  /users/profile/media?page=1&pageSize=10&isPrivate=false&userId=35
    const apiUrl = `${APIURL}/masters/gifts-category/with/gifts`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const CreateGift = async (name, categoryId, categoryName, credit, giftFile) => {
  try {
    let formData = new FormData()
    // formData = {
    //   name: name,
    //   giftCategoryId: categoryId,
    //   credit: credit,
    //   gift: giftFile,
    // }

    let fileName = categoryName + '.' + giftFile.name.split('.').pop()
    formData.append('gift', giftFile, fileName) // fileData[i].name .split(".")[0] + ".webp"
    formData.append(`credit`, credit)
    formData.append(`giftCategoryId`, categoryId)
    formData.append(`name`, name)

    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/gifts-category/1/gifts`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateGifts = async (name, categoryId, categoryName, credit, giftFile, giftId) => {
  try {
    let formData = new FormData()
    // formData = {
    //   name: name,
    //   giftCategoryId: categoryId,
    //   credit: credit,
    //   gift: giftFile,
    // }

    if (giftFile !== undefined && giftFile !== null && giftFile.length !== 0) {
      let fileName = categoryName + '.' + giftFile.name.split('.').pop()
      formData.append('gift', giftFile, fileName)
    }
    // fileData[i].name .split(".")[0] + ".webp"
    formData.append(`credit`, credit)
    formData.append(`giftCategoryId`, categoryId)
    formData.append(`name`, name)

    console.log('formData', formData)

    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/gifts-category/${categoryId}/gifts/${giftId}`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteGifts = async (giftCategoryId, giftId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/gifts-category/${giftCategoryId}/gifts/${giftId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

//master plugins

export const getConfigurationByName = async (name) => {
  try {
    const apiUrl = `/masters/configurations/types`

    let response = await request(apiUrl, API_TYPE.POST, {type: name}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateConfigurationByConfigID = async (ConfigID, values, gestureFile) => {
  try {
    let formData = new FormData()

    if (gestureFile === null) {
      formData = {
        values: JSON.stringify(values),
        configurationId: ConfigID,
      }
    } else {
      formData = {
        gesture: gestureFile,
        values: JSON.stringify(values),
        configurationId: ConfigID,
      }
    }

    const apiUrl = `/masters/configurations/update`

    let response = await request(apiUrl, API_TYPE.PUT, formData, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateNotificationConfigurationByConfigID = async (ConfigID, values, file) => {
  try {
    let formData = new FormData()

    formData = {
      gesture: file,
      values: JSON.stringify(values),
      configurationId: ConfigID,
    }

    const apiUrl = `/masters/configurations/notification`

    let response = await request(apiUrl, API_TYPE.PUT, formData, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// site-pricing plugin

export const getCreditAndPremiumPackageAmountPlans = async () => {
  try {
    const apiUrl = `/masters/packages/credits/both`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCreditPackageAmountPlans = async () => {
  try {
    const apiUrl = `/masters/packages/credits/`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const CreateCreditPackageAmountPlan = async (credit, totalAmount, amount) => {
  try {
    let formData = new FormData()
    formData = {
      credit: credit,
      totalAmount: totalAmount,
      amount: amount,
    }

    const apiUrl = `/masters/packages/credits/`

    let response = await request(apiUrl, API_TYPE.POST, formData, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateCreditPackageAmountPlan = async (pkgId, credit, totalAmount, amount) => {
  try {
    let formData = new FormData()
    formData = {
      credit: credit,
      totalAmount: totalAmount,
      amount: amount,
      creditPackageAmountId: pkgId,
    }

    const apiUrl = `/masters/packages/credits/`

    let response = await request(apiUrl, API_TYPE.PUT, formData, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getPremiumPackageAmountPlans = async () => {
  try {
    const apiUrl = `/masters/packages/premium/`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const CreatePremiumPackageAmountPlan = async (days, totalAmount, amount, pkgName) => {
  try {
    let formData = new FormData()
    formData = {
      days: days,
      totalAmount: totalAmount,
      amount: amount,
      premiumPackageName: pkgName,
    }

    const apiUrl = `/masters/packages/premium/`

    let response = await request(apiUrl, API_TYPE.POST, formData, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updatePremiumPackageAmountPlan = async (pkgId, days, totalAmount, amount, pkgName) => {
  try {
    let formData = new FormData()
    formData = {
      days: days,
      totalAmount: totalAmount,
      amount: amount,
      premiumPackageName: pkgName,
      premiumPackageAmountId: pkgId,
    }

    const apiUrl = `/masters/packages/premium/`

    let response = await request(apiUrl, API_TYPE.PUT, formData, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCreditPackages = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/packages/credits`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

//credit package details
export const CreateCreditPackages = async (heading, description, icon) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/packages/credits`

    console.log('icon', icon)

    let formData = new FormData()
    formData = {
      heading: heading,
      description: description,
      icon: icon,
    }

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteCreditPackages = async (creditPackageId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/packages/credits/${creditPackageId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateCreditPackages = async (data, icon) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/packages/credits/${data.creditPackageId}`

    let formData
    if (icon.length === 0) {
      formData = {
        heading: data.heading,
        description: data.description,
      }
    } else {
      formData = {
        heading: data.heading,
        description: data.description,
        icon: icon,
      }
    }

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

//get users orders
export const getUserOrdersList = async (page, pageSize, filter, tabValue) => {
  let {
    search,
    startDate,
    endDate,
    searchType,
    searchDateType,
    orderType,
    transactionStatus,
    modeOfPayment,
    premiumPlans,
    creditPlans,
    gender,
  } = filter

  try {
    let replacements = {
      'Google Pay': 'google pay',
      Phonepay: 'phonepay',
      'Apple Pay': 'apple pay',
    }

    modeOfPayment = modeOfPayment.map((method) => replacements[method] || method)

    let transactionStatusReplacements = {
      Pending: 'pending',
      Success: 'success',
      Failed: 'failed',
    }

    transactionStatus = transactionStatus.map(
      (method) => transactionStatusReplacements[method] || method
    )

    let data = {
      search,
      startDate,
      endDate,
      searchType,
      tabValue,
      orderType,
      searchDateType,
      transactionStatus,
      modeOfPayment,
      gender,
      PremiumPlanIds: premiumPlans,
      CreditPlanIds: creditPlans,
    }
    let accessToken = localStorage.getItem('accessToken')
    let apiUrl = `${APIURL}/users/purchase/order?page=${page}&pageSize=${pageSize}`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

//verification system

export const getUserVerificationList = async (page, pageSize) => {
  try {
    const apiUrl = `/web/users/verification/list?page=${page}&pageSize=${pageSize}`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateUserVerification = async (userID, verificationId, verifyStatus, reason) => {
  try {
    let formData = new FormData()
    if (verifyStatus === 'verified') {
      formData = {
        verifyStatus: verifyStatus,
        id: verificationId,
        userId: userID,
      }
    } else {
      formData = {
        verifyStatus: verifyStatus,
        reason: reason,
        id: verificationId,
        userId: userID,
      }
    }

    const apiUrl = `/web/users/verification`

    let response = await request(apiUrl, API_TYPE.PUT, formData, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// withdrawal requests

export const getUserWithDrawalRequestsList = async (page, pageSize, filter) => {
  try {
    let data = {
      page: page,
      pageSize: pageSize,
    }

    if (filter.search !== undefined) {
      data['search'] = filter.search
    }

    if (filter.userSearch !== undefined) {
      data['userSearch'] = filter.userSearch
    }

    if (filter.transactionStatus !== undefined) {
      data['transactionStatus'] = filter.transactionStatus
    }

    if (filter.startDate && filter.endDate) {
      data['startDate'] = filter.startDate
      data['endDate'] = filter.endDate
    }

    const apiUrl = `/web/withdraw/get`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateUserWithDrawalRequests = async (
  userId,
  withdrawalRequestId,
  transactionStatus,
  reason,
  credit,
  transactionId,
  adminUserId,
  amount
) => {
  try {
    let formData = new FormData()
    if (transactionStatus === 'completed') {
      formData = {
        transactionStatus: transactionStatus,
        transactionId: transactionId,
        adminUserId: adminUserId,
        credit: credit,
        withdrawMoney: amount,
        withdrawId: withdrawalRequestId,
      }
    } else {
      formData = {
        transactionStatus: transactionStatus,
        reason: reason,
        credit: credit,
        adminUserId: adminUserId,
        withdrawMoney: amount,
        withdrawId: withdrawalRequestId,
      }
    }

    const apiUrl = `/web/withdraw/update`

    let response = await request(apiUrl, API_TYPE.PUT, formData, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// anonymous users

export const getAllAnonymousUser = async (query) => {
  try {
    const apiUrl = `/web/anonymous/users/allUser?${query}`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllAdminUser = async (query) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users?${query}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllUserAnonymousStories = async (page, pageSize, isPrivate, userID, userName) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/anonymous/users/stories?page=${page}&pageSize=${pageSize}&isPrivate=${isPrivate}&userId=${userID}&userName=${userName}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllUserAnonymousMedia = async (page, pageSize, isPrivate, userID, userName) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/anonymous/users/media?page=${page}&pageSize=${pageSize}&isPrivate=${isPrivate}&userId=${userID}&userName=${userName}` //

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createNewAnonymousUser = async (values) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/anonymous/users`

    let formData = new FormData()
    formData = values

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })
    localStorage.setItem('anonymousUserId', response.data.data.userId)
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const checkUserName = async (userName) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/users/check/username`

    let formData = new FormData()
    formData = {
      userName: userName,
    }

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })
    console.log('2094', response)
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdateUserProfilePicture = async (UID, fileData) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = new FormData()
    formdata = {
      anonymousprofile: fileData,
      fromWeb: true,
    }

    const apiUrl = `${APIURL}/users/update/${UID}`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getUserMediaImages = async (uid) => {
  try {
    const apiUrl = `/web/users/profile/media/all/${uid}`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdateUserInterestAndQuestions = async (UID, interests, questions) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/users/update/${UID}`

    let formData = new FormData()
    formData = {
      interests: JSON.stringify(interests),
      questions: JSON.stringify(questions),
    }

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// legal information plugins (policy)

export const getAllPolicies = async (uid) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/policies`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdatePolicy = async (policyId, htmlText) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/policies/${policyId}`

    let formData = new FormData()
    formData = {
      html: htmlText, //JSON.stringify(htmlText),
    }

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// anonymous users chat
export const getAllNormalUserChatMembers = async (
  page,
  pageSize,
  search,
  moderatorId,
  tabValue
) => {
  try {
    console.log('moderatorId', moderatorId)
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/web/communications/all/app/chatMembers?page=${page}&pageSize=${pageSize}&tabValue=${tabValue}`

    if (search.length !== 0) {
      apiUrl += `&search=${search}`
    }

    if (moderatorId.length !== 0) {
      apiUrl += `&moderatorId=${moderatorId}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// Update folder structure config
export const UpdateFolderStructurePlugin = async (values, ConfigID) => {
  try {
    let data = {
      values: values,
      type: 'folder_structure',
      category: 'common',
      configId: ConfigID,
    }

    const apiUrl = `/masters/configurations/updateFolderStructureConfig`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

// interaction api start

export const getAllInteractionCount = async (userID) => {
  try {
    const apiUrl = `/web/users/interaction/allCount`

    let response = await request(apiUrl, API_TYPE.POST, {userId: userID}, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getLikesMeList = async (userID, page, pageSize) => {
  try {
    let data = {
      page: page,
      pageSize: pageSize,
      userId: userID,
    }

    const apiUrl = `/web/users/interaction/likes/me`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getVisitMeList = async (userID, page, pageSize) => {
  try {
    let data = {
      page: page,
      pageSize: pageSize,
      userId: userID,
    }

    const apiUrl = `/web/users/interaction/visit/me`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getMatchUserList = async (userID, page, pageSize) => {
  try {
    let data = {
      page: page,
      pageSize: pageSize,
      userId: userID,
    }

    const apiUrl = `/web/users/interaction/matched`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getMyLikesList = async (userID, page, pageSize) => {
  try {
    let data = {
      page: page,
      pageSize: pageSize,
      userId: userID,
    }

    const apiUrl = `/web/users/interaction/likes/my`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getMyVisitList = async (userID, page, pageSize) => {
  try {
    let data = {
      page: page,
      pageSize: pageSize,
      userId: userID,
    }

    const apiUrl = `/web/users/interaction/visit/my`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// interaction api end

// admin users api

export const getAllRoles = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/roles/`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createAdminUser = async (values) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/register`

    let formData = new FormData()
    formData = values

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAdminUserProfile = async (userId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/web/users/${userId}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateAdminUser = async (values, userId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/${userId}`

    let formData = new FormData()
    formData = values

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteAdminUser = async (userId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/${userId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const activeInactiveAdminUser = async (userIDs, status) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      userIds: userIDs,
      status: status,
    }

    const apiUrl = `${APIURL}/web/users/updateStatus`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getChatModeratorList = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/web/users/getChatModerators`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateSingleModerator = async (userId, newModeratorId) => {
  try {
    let data = {
      userId: userId,
      newModeratorId: newModeratorId,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/updateSingleModerator`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateModeratorAndAssignEqually = async (allUsers, oldModeratorId) => {
  try {
    let data = {
      allUsers: allUsers,
      oldModeratorId: oldModeratorId,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/updateModeratorAndDivideEqually`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateMultipleUserModerator = async (allUsers, newModeratorId) => {
  try {
    let data = {
      allUsers: allUsers,
      newModeratorId: newModeratorId,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/updateMultipleUserModerator`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const sendNotificationToSelectedUser = async (userIds, message, title, imageUrl) => {
  try {
    let data = {
      userIds: userIds,
      message: message,
      title: title,
      imageUrl: imageUrl,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/sendNotificationToSelectedUser`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// support Reason screen

export const getAllSupportReasons = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/supports`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createSupportReasons = async (reason) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      reason: reason,
    }

    const apiUrl = `${APIURL}/masters/supports`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateSupportReasons = async (supportId, reason) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      reason: reason,
    }

    const apiUrl = `${APIURL}/masters/supports/${supportId}`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// support screen

export const getAllUsersSupports = async (page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/web/supports?page=${page}&pageSize=${pageSize}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUsersSupportsChat = async (userId, supportId, page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/users/${userId}/support/${supportId}?page=${page}&pageSize=${pageSize}&isWeb=true`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const AssignSupportTicket = async (assigneeId, supportId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      assignee: assigneeId,
    }

    const apiUrl = `${APIURL}/web/supports/${supportId}/assign`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdateSupportTicketStatus = async (userId, supportId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      userId: userId,
      supportStatus: 'completed',
    }

    const apiUrl = `${APIURL}/web/supports/${supportId}/update`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllAnalytics = async (page, pageSize, filter) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/all?page=${page}&pageSize=${pageSize}&type=${filter.duration}`

    if (filter.startDate && filter.endDate) {
      apiUrl += `&startDate=${filter.startDate}&endDate=${filter.endDate}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createAnalytics = async (marketingCost) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      marketingCost: marketingCost,
    }

    const apiUrl = `${APIURL}/masters/analytics/create`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateAnalytics = async (analytics) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/analytics/update`

    let response = await axios.put(apiUrl, analytics, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const recalculateDailyAnalytics = async (analyticsId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/analytics/recalculate?analyticsId=${analyticsId}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const recalculateAllAnalytics = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/analytics/recalculate/all`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getTodaysAnalytics = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/currentAnalytics`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCurrentWeekAnalytics = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/currentWeek`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCurrentMonthAnalytics = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/currentMonth`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCurrentYearAnalytics = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/currentYear`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserAnalytics = async (filter, dateFormat) => {
  let {duration, startDate, endDate, view} = filter
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/user?duration=${duration}&view=${view}&format=${dateFormat}`

    if (duration !== 'today' && duration !== 'yesterday') {
      startDate = moment(startDate).format('YYYY-MM-DD')
      endDate = moment(endDate).format('YYYY-MM-DD')
      apiUrl += `&startDate=${startDate}&endDate=${endDate}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCreditOrderAnalytics = async (
  filter,
  dateFormat,
  orderTypeFilter,
  paymentModeFilter
) => {
  let {duration, startDate, endDate, view, chartType} = filter
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/creditOrder`

    orderTypeFilter = Object.keys(orderTypeFilter).filter((key) => orderTypeFilter[key] === true)

    paymentModeFilter = Object.keys(paymentModeFilter).filter(
      (key) => paymentModeFilter[key] === true
    )

    let replacedPaymentMethods = paymentModeFilter.map((method) => {
      if (method === 'googlePay') {
        return 'google pay'
      } else if (method === 'phonePay') {
        return 'phonepay'
      } else if (method === 'applePay') {
        return 'apple pay'
      } else {
        return method
      }
    })

    let data = {
      chartType: chartType,
      duration: duration,
      type: orderTypeFilter,
      view: view,
      format: dateFormat,
      paymentModes: replacedPaymentMethods,
    }

    if (duration !== 'today' && duration !== 'yesterday') {
      data = {
        ...data,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      }
    }

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getPremiumOrderAnalytics = async (
  filter,
  dateFormat,
  orderTypeFilter,
  paymentModeFilter
) => {
  let {duration, startDate, endDate, view, chartType} = filter
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/premiumOrder`

    orderTypeFilter = Object.keys(orderTypeFilter).filter((key) => orderTypeFilter[key] === true)

    paymentModeFilter = Object.keys(paymentModeFilter).filter(
      (key) => paymentModeFilter[key] === true
    )

    let replacedPaymentMethods = paymentModeFilter.map((method) => {
      if (method === 'googlePay') {
        return 'google pay'
      } else if (method === 'phonePay') {
        return 'phonepay'
      } else if (method === 'applePay') {
        return 'apple pay'
      } else {
        return method
      }
    })

    let data = {
      chartType: chartType,
      duration: duration,
      type: orderTypeFilter,
      view: view,
      format: dateFormat,
      paymentModes: replacedPaymentMethods,
    }

    if (duration !== 'today' && duration !== 'yesterday') {
      data = {
        ...data,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      }
    }

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getTotalOrderAnalytics = async (
  filter,
  dateFormat,
  orderTypeFilter,
  paymentModeFilter
) => {
  let {duration, startDate, endDate, view} = filter
  try {
    let apiUrl = `${APIURL}/masters/analytics/totalOrder`

    let accessToken = localStorage.getItem('accessToken')

    orderTypeFilter = Object.keys(orderTypeFilter).filter((key) => orderTypeFilter[key] === true)

    paymentModeFilter = Object.keys(paymentModeFilter).filter(
      (key) => paymentModeFilter[key] === true
    )

    let replacedPaymentMethods = paymentModeFilter.map((method) => {
      if (method === 'googlePay') {
        return 'google pay'
      } else if (method === 'phonePay') {
        return 'phonepay'
      } else if (method === 'applePay') {
        return 'apple pay'
      } else {
        return method
      }
    })

    let data = {
      duration: duration,
      type: orderTypeFilter,
      view: view,
      format: dateFormat,
      paymentModes: replacedPaymentMethods,
    }

    if (duration !== 'today' && duration !== 'yesterday') {
      data = {
        ...data,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      }
    }

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getGenderWiseAnalytics = async (type) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/genderWise?type=${type}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getOrderAnalytics = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/premiumAnalytics`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCountryWiseUserAnalytics = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/countryWiseUser`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getStateWiseUserAnalytics = async (countryName, stateFilter) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/stateWiseUser?country=${countryName}&stateFilter=${stateFilter}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getCityWiseUserAnalytics = async (countryName, stateName, cityFilter) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/cityWiseUser?state=${stateName}&country=${countryName}&cityFilter=${cityFilter}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getDashboardAnalytics = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/analytics/analytics-dashboard`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllAdsPlan = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/masters/ads-plan`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createAdsPlan = async (data) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      type: data.type,
      premiumPlanId: data.premiumPlanId,
      creditAndDays: data.creditAndDays,
      adsType: data.adsType,
    }

    const apiUrl = `${APIURL}/masters/ads-plan`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateAdsPlan = async (data) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let formdata = new FormData()
    formdata = {
      type: data.type,
      premiumPlanId: data.premiumPlanId,
      creditAndDays: data.creditAndDays,
      adsType: data.adsType,
    }

    const apiUrl = `${APIURL}/masters/ads-plan/${data.adsPlanId}`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteAdsPlan = async (planId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/masters/ads-plan/${planId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const scheduleCron = async (type, time, title, message, imageUrl) => {
  try {
    let data = {
      type: type,
      time: time,
      title: title,
      message: message,
      imageUrl: imageUrl,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/notifications/scheduleCron`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getSideBarCount = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/web/users/sidebarCount`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllBlockedUserList = async (page, pageSize) => {
  try {
    let data = {
      page: page,
      pageSize: pageSize,
      //userId: userId,
    }

    const apiUrl = `/web/users/block/get`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserAllStatistics = async (userType) => {
  try {
    let data = {type: userType}

    const apiUrl = `/web/anonymous/users/statistics`

    let response = await request(apiUrl, API_TYPE.POST, data, true)

    return response
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllAnonymousUserForCreateChatRoom = async (
  page,
  pageSize,
  state,
  userId,
  searchTerm
) => {
  try {
    console.log('state', state)
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/anonymous/users/get-anonymous-user/chat-room?page=${page}&pageSize=${pageSize}&state=${state}&userId=${userId}&search=${searchTerm}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const sendVisitFromAnonymousChat = async (senderId, receiverId) => {
  try {
    let data = {
      senderId: senderId,
      receiverId: receiverId,
    }

    //  http://192.168.1.34:9090/web/users/send/visit

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/send-visit`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const sendLikeFromAnonymousChat = async (senderId, receiverId) => {
  try {
    let data = {
      senderId: senderId,
      receiverId: receiverId,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/web/users/send-like`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const readAllAnonymousUserMessage = async (userID, registerFrom) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/communications/chat/users/${userID}/messages/read/all?registerFrom=${registerFrom}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updatePinStatus = async (userID, pinStatus) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = {
      pin: pinStatus,
    }

    const apiUrl = `${APIURL}/communications/chat/users/${userID}/pin`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createChatMemberAndSendMessage = async (senderId, receiverId, message) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = {
      senderId: senderId,
      receiverId: receiverId,
      message: message,
    }

    const apiUrl = `${APIURL}/web/communications/create-chat-room`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getInvoiceData = async (filter) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    let apiUrl = `${APIURL}/web/users/invoice/create?`

    if (filter?.startDate) {
      apiUrl += `startDate=${filter?.startDate}`
    }

    if (filter?.endDate) {
      apiUrl += `&endDate=${filter?.endDate}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserReferralWithPagination = async (userID) => {
  try {
    const apiUrl = `/web/users/referral/${userID}`

    let response = await request(apiUrl, API_TYPE.GET, {}, true)

    return response
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getChatModeratorsAnalytics = async (
  filter,
  premiumPlanFilter,
  creditPlanFilter,
  moderatorId
) => {
  try {
    let premiumPlanIds = Object.keys(premiumPlanFilter).filter(
      (key) => premiumPlanFilter[key] === true
    )
    let creditPlanIds = Object.keys(creditPlanFilter).filter(
      (key) => creditPlanFilter[key] === true
    )

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/analytics/moderator`

    let data = {
      startDate: filter?.startDate,
      endDate: filter?.endDate,
      premiumOrderIds: premiumPlanIds,
      creditOrderIds: creditPlanIds,
      moderatorId: moderatorId,
    }

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getChatModeratorsWiseAnalytics = async (
  filter,
  dateFormat,
  moderatorId,
  premiumPlanFilter,
  creditPlanFilter
) => {
  let {duration, startDate, endDate, view} = filter
  try {
    let accessToken = localStorage.getItem('accessToken')

    let premiumPlanIds = Object.keys(premiumPlanFilter).filter(
      (key) => premiumPlanFilter[key] === true
    )
    let creditPlanIds = Object.keys(creditPlanFilter).filter(
      (key) => creditPlanFilter[key] === true
    )

    let apiUrl = `${APIURL}/masters/analytics/moderator/${moderatorId}`

    let data = {
      duration: duration,
      view: view,
      format: dateFormat,
      creditPlanIds: creditPlanIds,
      premiumPlanIds: premiumPlanIds,
    }

    if (duration !== 'today' && duration !== 'yesterday') {
      data = {
        ...data,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      }
    }

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getStaticDataForModeratorDashboard = async (moderatorId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/analytics/moderator/dashboard?moderatorId=${moderatorId}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const deleteAccountPermanent = async (userIDs) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = {
      userIds: userIDs,
    }

    const apiUrl = `${APIURL}/users/delete/all`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUserLiveStreaming = async (userId, page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/users/${userId}/livestream/admin?page=${page}&pageSize=${pageSize}&userId=${userId}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const deleteUserLiveStreaming = async (userId, streamingId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/users/${userId}/livestream/delete?streamingId=${streamingId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getAllUserLiveStreaming = async (page, pageSize, filter) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let apiUrl = `${APIURL}/users/1/livestream/admin/all?page=${page}&pageSize=${pageSize}`

    if (filter.search) {
      apiUrl += `&search=${filter.search}`
    }

    if (filter.streamType) {
      apiUrl += `&streamType=${filter.streamType}`
    }

    if (filter.streamStatus) {
      apiUrl += `&streamStatus=${filter.streamStatus}`
    }

    if (filter.startDate && filter.endDate) {
      apiUrl += `&startDate=${filter.startDate}&endDate=${filter.endDate}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getUserVideoCalls = async (userId, page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/communications/videoCalls/users/${userId}?page=${page}&pageSize=${pageSize}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const deleteUserVideoCall = async (videoCallId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/communications/videoCalls/delete?videoCallId=${videoCallId}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getAllVideoCalls = async (page, pageSize, filter) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let apiUrl = `${APIURL}/communications/videoCalls/admin/all/?page=${page}&pageSize=${pageSize}`

    if (filter.startDate && filter.endDate) {
      apiUrl += `&startDate=${filter.startDate}&endDate=${filter.endDate}`
    }

    if (filter.search) {
      apiUrl += `&search=${filter.search}`
    }

    if (filter.callStatus) {
      apiUrl += `&callStatus=${filter.callStatus}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })
    return response.data
  } catch (error) {
    console.log(error.message)
    return error.message
  }
}

export const getAllAdsAnalyticsList = async (page, pageSize) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/ads-analytics?page=${page}&pageSize=${pageSize}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addNewCampaignInAdsAnalytics = async (data) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = {
      campaignName: data?.campaignName,
      platform: data?.platform,
      budgetType: data?.budgetType,
      campaignType: data?.campaignType,
      impression: data?.impression,
      campaignSource: data?.campaignSource,
      devicePlatform: data?.devicePlatform,
    }

    const apiUrl = `${APIURL}/masters/ads-analytics`

    let response = await axios.post(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateCampaignInAdsAnalytics = async (data) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = {
      campaignName: data?.campaignName,
      platform: data?.platform,
      budgetType: data?.budgetType,
      campaignType: data?.campaignType,
      impression: data?.impression,
      status: data?.status,
      campaignSource: data?.campaignSource,
      devicePlatform: data?.devicePlatform,
    }

    const apiUrl = `${APIURL}/masters/ads-analytics/${data.campaignId}`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getAllCampaignAdsAnalyticsList = async (page, pageSize, campaignId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/campaign-ads-analytics?page=${page}&pageSize=${pageSize}&campaignId=${campaignId}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateCampaignAdsAnalytics = async (data) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let formdata = {
      budget: data?.budget,
      impression: data?.impression,
    }

    const apiUrl = `${APIURL}/masters/campaign-ads-analytics/${data.id}`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateAllROASinAdsCampaign = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/masters/ads-analytics/update-all-budget`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}
