import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const LiveSteamingPlugin = () => {
  const [configID, setConfigID] = useState(0)

  const [liveStreamingConfig, setLiveStreamingConfig] = useState<any>({
    isLiveStreamingEnabled: true,
    streamStatus: 'public',
    perMinuteCost: '1',
    freeIntervalTime: '3600',
    isTransferCredit: true,
    deductCreditGender: '1',
    transferCreditPercentage: '50',
    premiumUsersCanViewStreamOnly: false,
    premiumUsersCanStreamOnly: false,
    verifiedUsersCanStreamOnly: false,
    genderAllowedToStream: '1',
    creditTransferToStreamerPercentage: '50',
    agoraAppID: '',
    agoraAppCertificate: '',
    agoraTokenExpirationInSecond: '',
  })

  useEffect(() => {
    getLiveSteamingConfiguration()
  }, [])

  const handleLiveStreamingChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    if (
      name === 'isTransferCredit' ||
      name === 'isLiveStreamingEnabled' ||
      name === 'premiumUsersCanViewStreamOnly' ||
      name === 'premiumUsersCanStreamOnly' ||
      name === 'verifiedUsersCanStreamOnly'
    ) {
      setLiveStreamingConfig({...liveStreamingConfig, [name]: value})
      updateLiveSteamingConfiguration({...liveStreamingConfig, [name]: value})
    } else {
      setLiveStreamingConfig({...liveStreamingConfig, [name]: event.target.value})
      if (name === 'deductCreditGender' || name === 'genderAllowedToStream') {
        let value1 = event.target.value === 0 ? null : event.target.value
        updateLiveSteamingConfiguration({...liveStreamingConfig, [name]: value1})
      }
    }
  }

  const onLiveStreamingBlurUpdate = () => {
    updateLiveSteamingConfiguration(liveStreamingConfig)
  }

  const getLiveSteamingConfiguration = async () => {
    let result = await getConfigurationByName('live-streaming')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigID(result.data.id)
      setLiveStreamingConfig({
        isLiveStreamingEnabled: parsedData.isLiveStreamingEnabled,
        streamStatus: parsedData.streamStatus,
        perMinuteCost: parsedData.perMinuteCost,
        freeIntervalTime: parsedData.freeIntervalTime,
        isTransferCredit: parsedData.isTransferCredit,
        deductCreditGender: parsedData.deductCreditGender,
        transferCreditPercentage: parsedData.transferCreditPercentage,
        premiumUsersCanViewStreamOnly: parsedData.premiumUsersCanViewStreamOnly,
        premiumUsersCanStreamOnly: parsedData.premiumUsersCanStreamOnly,
        verifiedUsersCanStreamOnly: parsedData.verifiedUsersCanStreamOnly,
        genderAllowedToStream: parsedData.genderAllowedToStream,
        creditTransferToStreamerPercentage: parsedData.creditTransferToStreamerPercentage,
        agoraAppID: parsedData.agoraAppID,
        agoraAppCertificate: parsedData.agoraAppCertificate,
        agoraTokenExpirationInSecond: parsedData.agoraTokenExpirationInSecond,
      })
    }
  }

  const updateLiveSteamingConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getLiveSteamingConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Live Streaming plugin</strong>
            </p>
            <p className='text-muted'>Enable or disable Live Streaming plugin</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isLiveStreamingEnabled'
                checked={liveStreamingConfig.isLiveStreamingEnabled}
                onChange={(event) => handleLiveStreamingChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Select Stream Type</strong>
            </p>
            <p className='text-muted'>
              If the stream status is set to private, only the user's credits will be deducted, and
              they will receive a free interval of time upon joining.
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessageGender'>Stream Type</label>
              <br />
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='streamStatus'
                data-hide-search='true'
                defaultValue={liveStreamingConfig.streamStatus}
                name='streamStatus'
                value={liveStreamingConfig.streamStatus}
                onChange={(event) => handleLiveStreamingChange(event)}
                onBlur={onLiveStreamingBlurUpdate}
              >
                <option value='public'>Public</option>
                <option value='private'>Private</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Free Interval Time</strong>
            </p>
            <p className='text-muted'>
              Enter the time in seconds that the user will receive as free time to join.
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Free Interval Time</label>
              <br />

              <input
                type='number'
                className='form-control'
                name='freeIntervalTime'
                value={liveStreamingConfig.freeIntervalTime}
                onChange={(event) => handleLiveStreamingChange(event)}
                onBlur={onLiveStreamingBlurUpdate}
                disabled={liveStreamingConfig.streamStatus === 'public'}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Per Minute Cost</strong>
            </p>
            <p className='text-muted'>
              This cost will be deducts per Minutes
              <p className='text-danger'>Set Per Minute Cost Minimum 10</p>
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Enter Per Minute Cost</label>
              <br />

              <input
                type='number'
                className='form-control'
                name='perMinuteCost'
                value={liveStreamingConfig.perMinuteCost}
                onChange={(event) => handleLiveStreamingChange(event)}
                onBlur={onLiveStreamingBlurUpdate}
                disabled={liveStreamingConfig.streamStatus === 'public'}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer Per Minute Cost Credit Percentage</strong>
            </p>
            <p className='text-muted'>Transfer Credit Percentage to Streamer Account </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Transfer Credit Percentage</label>
              <br />

              <input
                type='number'
                className='form-control'
                name='creditTransferToStreamerPercentage'
                value={liveStreamingConfig.creditTransferToStreamerPercentage}
                onChange={(event) => handleLiveStreamingChange(event)}
                onBlur={onLiveStreamingBlurUpdate}
                disabled={liveStreamingConfig.streamStatus === 'public'}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Gender Allowed To Start A Live Stream</strong>
            </p>
            <p className='text-muted'>Selected Gender Only Allowed To Start A Live Stream</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessageGender'>Allowed Gender To Start Live Stream</label>
              <br />
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='genderAllowedToStream'
                data-hide-search='true'
                defaultValue={liveStreamingConfig.genderAllowedToStream}
                name='genderAllowedToStream'
                value={liveStreamingConfig.genderAllowedToStream}
                onChange={(event) => handleLiveStreamingChange(event)}
              >
                <option value={0}>All Gender</option>
                <option value={1}>Male</option>
                <option value={2}>Female</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Premium User Can Only View Stream</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Premium User Can Only View Stream</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='premiumUsersCanViewStreamOnly'
                checked={liveStreamingConfig.premiumUsersCanViewStreamOnly}
                onChange={(event) => handleLiveStreamingChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Premium User Can Only Create Stream</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Premium User Can Only Create Stream</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='premiumUsersCanStreamOnly'
                checked={liveStreamingConfig.premiumUsersCanStreamOnly}
                onChange={(event) => handleLiveStreamingChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Verified User Can Only Create Stream</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Verified User Can Only Create Stream</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='verifiedUsersCanStreamOnly'
                checked={liveStreamingConfig.verifiedUsersCanStreamOnly}
                onChange={(event) => handleLiveStreamingChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer credits of gifts to receiver</strong>
            </p>
            <p className='text-muted'>
              Enable this htmlFor transfer the credit cost of the gift to the receiver of the
              message
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isTransferCredit'
                checked={liveStreamingConfig.isTransferCredit}
                onChange={(event) => handleLiveStreamingChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer Gift Credit Percentage</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Transfer Credit Percentage </label>
              <br />

              <input
                type='number'
                className='form-control'
                name='transferCreditPercentage'
                value={liveStreamingConfig.transferCreditPercentage}
                onChange={(event) => handleLiveStreamingChange(event)}
                onBlur={onLiveStreamingBlurUpdate}
                disabled={!liveStreamingConfig.isTransferCredit}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Charge credits to specific gender for gift</strong>
            </p>
            <p className='text-muted'>
              Charge credits per message only to the selected gender for gift
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessageGender'>Charge credits gender</label>
              <br />
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='gender'
                data-hide-search='true'
                defaultValue={liveStreamingConfig.deductCreditGender}
                name='deductCreditGender'
                value={liveStreamingConfig.deductCreditGender}
                onChange={(event) => handleLiveStreamingChange(event)}
              >
                <option value={0}>All Gender</option>
                <option value={1}>Male</option>
                <option value={2}>Female</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Agora App Id</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Agora App Id</label>
              <br />
              <input
                type='text'
                className='form-control'
                name='agoraAppID'
                value={liveStreamingConfig.agoraAppID}
                onChange={(event) => handleLiveStreamingChange(event)}
                onBlur={onLiveStreamingBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Agora App Certificate</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Agora App Certificate</label>
              <br />
              <input
                type='text'
                className='form-control'
                name='agoraAppCertificate'
                value={liveStreamingConfig.agoraAppCertificate}
                onChange={(event) => handleLiveStreamingChange(event)}
                onBlur={onLiveStreamingBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Agora App Token Expire</strong>
            </p>
            <p className='text-muted'>Enter In seconds</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessage'>Agora App Token Expire</label>
              <br />
              <input
                type='number'
                className='form-control'
                name='agoraTokenExpirationInSecond'
                value={liveStreamingConfig.agoraTokenExpirationInSecond}
                onChange={(event) => handleLiveStreamingChange(event)}
                onBlur={onLiveStreamingBlurUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveSteamingPlugin
