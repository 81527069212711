import clsx from 'clsx'
import {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'

const CustomPagination = (props) => {
  const {pageSize, setPageSize, totalPage, cb} = props

  const [page, setPage] = useState<any>(0) // here we have set page as 0 bcs paginate first page start with 0

  const updatePage = ({selected: selectedPage}) => {
    setPage(parseInt(selectedPage))
    cb(parseInt(selectedPage + 1), pageSize)
  }

  const updatePageSize = (e) => {
    setPageSize(e.target.value)
    setPage(0) // here we have set page as 0 bcs paginate first page start with 0
    cb(1, e.target.value)
  }

  const pageJump = (page: any) => {
    let total_page = totalPage || 1
    if (total_page < page && total_page !== page) {
      return
    }
    setPage(page)
  }

  const onEnterPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      let currentPage = page
      if (currentPage < 0) {
        currentPage = 1
      }
      cb(currentPage, pageSize)
    }
  }

  return (
    <div className='d-flex justify-content-between'>
      <div>
        <select
          className='form-select h-30px mt-2'
          data-kt-select2='true'
          data-allow-clear='true'
          defaultValue={pageSize}
          style={{padding: '0.3rem 2rem 0.3rem 1rem'}}
          onChange={(e) => updatePageSize(e)}
        >
          <option value='10'>10</option>
          <option value='30'>30</option>
          <option value='50'>50</option>
          <option value='100'>100</option>
          <option value='500'>500</option>
          <option value='1000'>1000</option>
        </select>
      </div>
      <div className='d-flex mt-2'>
        <div className='page-input-wrapper'>
          <input
            type='number'
            name='pageJump'
            className={clsx('page-input')}
            autoComplete='off'
            onChange={(e) => pageJump(parseInt(e.target.value) - 1)}
            value={page + 1}
            onKeyDown={onEnterPress}
          />
        </div>
        <div>
          <ReactPaginate
            previousLabel={<i className='fa-solid fa-chevron-left'></i>}
            nextLabel={<i className='fa-solid fa-chevron-right'></i>}
            pageCount={totalPage}
            onPageChange={updatePage}
            forcePage={page}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
          />
        </div>
      </div>
    </div>
  )
}

export default CustomPagination
