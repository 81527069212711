import React, {useEffect, useState} from 'react'
import {KTCardBody} from '../../../../_metronic/helpers'
import {DateWithTimeFormatter} from '../../../../utils/DateUtils'
import CustomPagination from '../../../../_metronic/partials/componants/Pagination'
import ToastUtils, {ErrorToastUtils} from '../../../../utils/ToastUtils'
import {
  getAllCampaignAdsAnalyticsList,
  updateCampaignAdsAnalytics,
} from '../../../../API/api-endpoint'
import clsx from 'clsx'
import {Link} from 'react-router-dom'

const CampaignAdsAnalytics = ({selectedAdsCampaign, isViewMode, setIsViewMode}) => {
  const [adsAnalyticsList, setAdsAnalyticsList] = useState<any>([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)

  const [selectedCampaign, setSelectedCampaign] = useState<any>({
    budget: 0,
    impression: 0,
  })

  useEffect(() => {
    getCampaignAdsAnalyticsList(page, pageSize)
  }, [])

  const getPagination = (page: any, pageSize: any) => {
    if (page === 0 || page === 1) {
      page = 1
    }
    setPage(page)
    getCampaignAdsAnalyticsList(page, pageSize)
  }

  const getCampaignAdsAnalyticsList = async (page: any, pageSize: any) => {
    let result = await getAllCampaignAdsAnalyticsList(
      page,
      pageSize,
      selectedAdsCampaign?.campaignId
    )
    if (result.status === 200) {
      setAdsAnalyticsList(result.data)
      setTotalPage(result.totalPage)
    } else {
      ErrorToastUtils()
    }
  }

  const handleUpdateCampaign = async () => {
    let result = await updateCampaignAdsAnalytics(selectedCampaign)
    if (result.status === 200) {
      getCampaignAdsAnalyticsList(page, pageSize)
      ToastUtils({
        type: 'success',
        message: 'Campaign Update successfully',
      })
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary mb-2' onClick={() => setIsViewMode(false)}>
          Back
        </button>
      </div>

      <div className='d-flex justify-content-start mt-2 mb-2 gap-4'>
        <div>
          <label className='form-check-label mt-3'>Campaign Name</label>
          <input
            type='text'
            name='campaignName'
            className={clsx('form-control  mb-3 mb-lg-0')}
            autoComplete='off'
            value={selectedAdsCampaign.campaignName}
            disabled={true}
          />
        </div>
        <div>
          <label className='form-check-label mt-3'>Campaign Link</label>
          <input
            type='text'
            name='campaignLink'
            className={clsx('form-control  mb-3 mb-lg-0')}
            autoComplete='off'
            value={selectedAdsCampaign.campaignLink}
            disabled={true}
          />
        </div>

        <div>
          <label className='form-check-label mt-3'>Campaign Type</label>
          <input
            type='text'
            name='campaignType'
            className={clsx('form-control  mb-3 mb-lg-0')}
            autoComplete='off'
            value={selectedAdsCampaign.campaignType}
            disabled={true}
          />
        </div>
        <div>
          <label className='form-check-label mt-3'>Budget Type</label>
          <input
            type='text'
            name='budgetType'
            className={clsx('form-control  mb-3 mb-lg-0')}
            autoComplete='off'
            value={selectedAdsCampaign.budgetType}
            disabled={true}
          />
        </div>
      </div>

      <KTCardBody className='py-4 card'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <td>Budget</td>
                <td>Imp</td>
                <td>Clicks</td>
                <td>CTR</td>
                <td>CPC</td>
                <td>Installs</td>
                <td>CPI</td>
                <td>ROAS</td>
                <td>Revenue</td>
                <td>Log in User Count</td>
                <td>Complete Profile User Count</td>
                <td>Date</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody className='text-gray-600 '>
              {adsAnalyticsList.length !== 0 &&
                adsAnalyticsList.map((adsAnalytics: any, index: any) => {
                  let CTR = adsAnalytics?.impression
                    ? (adsAnalytics.clicks * 100) / adsAnalytics.impression
                    : 0

                  CTR = parseFloat(CTR.toFixed(2))

                  let CPC = adsAnalytics?.clicks ? adsAnalytics.budget / adsAnalytics.clicks : 0

                  CPC = parseFloat(CPC.toFixed(2))

                  let CPI = adsAnalytics?.budget
                    ? adsAnalytics?.budget / adsAnalytics?.installCount
                    : 0

                  CPI = parseFloat(CPI.toFixed(2))

                  let ROAS =
                    adsAnalytics?.roas && adsAnalytics?.budget > 0
                      ? adsAnalytics.roas / adsAnalytics.budget
                      : 0

                  ROAS = parseFloat(ROAS.toFixed(2))

                  return (
                    <tr key={index}>
                      <td>
                        <span>{'₹ ' + adsAnalytics?.budget}</span>
                      </td>
                      <td>
                        <span>{adsAnalytics?.impression}</span>
                      </td>
                      <td>
                        <span>{adsAnalytics?.clicks}</span>
                      </td>
                      <td>
                        <span>{CTR + ' %'}</span>
                      </td>
                      <td>
                        <span>{'₹ ' + CPC}</span>
                      </td>
                      <td>
                        <Link
                          to={`/apps/user-management/users/${adsAnalytics.campaignId}`}
                          className='fs-7 cursor-pointer'
                          onClick={() => {
                            let date = adsAnalytics.createdAt
                            localStorage.setItem('date', JSON.stringify(date))
                          }}
                        >
                          <span>{adsAnalytics?.installCount}</span>
                        </Link>
                      </td>
                      <td>
                        <span>{'₹ ' + CPI}</span>
                      </td>
                      <td>
                        <span>{ROAS}x</span>
                      </td>
                      <td>
                        <span>{'₹ ' + adsAnalytics?.roas}</span>
                      </td>
                      <td>
                        <span>{adsAnalytics?.loginUserCount}</span>
                      </td>
                      <td>
                        <span>{adsAnalytics?.completeProfileCount}</span>
                      </td>
                      <td>
                        <span>{DateWithTimeFormatter(adsAnalytics?.createdAt)}</span>
                      </td>
                      <td>
                        <td>
                          <button
                            data-bs-toggle='modal'
                            data-bs-target='#update_campaign'
                            className='btn small'
                            onClick={() => {
                              setSelectedCampaign(adsAnalytics)
                            }}
                          >
                            <i className='fa-solid fa-ellipsis-vertical text-black'></i>
                          </button>
                        </td>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className='card-footer'>
          {adsAnalyticsList.length !== 0 && (
            <CustomPagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalPage={totalPage}
              cb={getPagination}
            />
          )}
        </div>
      </KTCardBody>

      <div className='modal fade' tabIndex={-1} id='update_campaign'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Update Campaign</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() =>
                  setSelectedCampaign({
                    budget: 0,
                    impression: 0,
                  })
                }
              >
                <i className='fa-solid fa-xmark'></i>
              </div>
            </div>
            <div className='modal-body'>
              <label className='form-check-label mt-3'>Budget</label>
              <input
                placeholder='Enter Budget'
                type='number'
                name='budget'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={selectedCampaign.budget}
                onChange={(e) =>
                  setSelectedCampaign({
                    ...selectedCampaign,
                    budget: e.target.value,
                  })
                }
              />
              &nbsp;
              <label className='form-check-label mt-3'>Impression</label>
              <input
                placeholder='Enter Impression'
                type='number'
                name='impression'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={selectedCampaign.impression}
                onChange={(e) =>
                  setSelectedCampaign({
                    ...selectedCampaign,
                    impression: e.target.value,
                  })
                }
              />
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={() =>
                  setSelectedCampaign({
                    budget: 0,
                    impression: 0,
                  })
                }
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={handleUpdateCampaign}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CampaignAdsAnalytics
