import {AuthModel} from './_models'
import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    //console.log("lsValue",lsValue)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

// Axios setup function with encryption and decryption interceptors
export function setupAxios(axios: AxiosInstance): void {
  //   axios.defaults.headers.Accept = 'application/json'
  //   axios.defaults.headers.isencryption = 'true'
  // Request interceptor to add Authorization header and encrypt data
  //   axios.interceptors.request.use(
  //     (config: any) => {
  //       const auth = getAuth()
  //       if (auth && auth.token) {
  //         config.headers['x-access-token'] = `Bearer ${auth.token}`
  //       }
  //       // Encrypt the request data if isEncryption is set to true
  //       //   if (config.isEncryption) {
  //       //     config.data = {dataStr: encryptData(config.data)}
  //       //   }
  //       return config
  //     },
  //     (err) => Promise.reject(err)
  //   )
  //   // Response interceptor to decrypt data if encryption was used
  //   axios.interceptors.response.use(
  //     (response: any) => {
  //       const config = response.config
  //       //   if (config.isencryption && response.data && response.data.data) {
  //       //     response.data.data = decryptData(response.data.data) // Decrypt payload
  //       //   }
  //       return response
  //     },
  //     (error) => Promise.reject(error)
  //   )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
